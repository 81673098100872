import { Base64 } from "js-base64";
import $ from 'jquery'

//成功回调
export function success_jsonpCallback(json) {
  console.log(json);
}
/*通用链接云中间件接口函数*/
export function esaLinkServerPost(commond,code,params,fuc,retparam,ishttps)
{
	var inputdata = {"command" : commond,"code" : code,"parameter" : params}; 
	var data = JSON.stringify(inputdata);
	var dataStr = Base64.encode(data);
	var urldata;
	/*
	if (ishttps)
	{
		urldata = "https://localhost.easysign.cn:1991?data=";
	}else{
		urldata = "http://127.0.0.1:1990?data=";
	}
	*/
	
	if (ishttps)
	{
		urldata = "https://localhost.easysign.cn:36717?data=";
	}else{
		urldata = "http://127.0.0.1:36716?data=";
	}
	/*
	if (ishttps)
	{
		urldata = "https://localhost.easysign.cn:1993?data=";
	}else{
		urldata = "http://127.0.0.1:1992?data=";
	}
	*/
	
	
	var ajax = $.ajax({
		type : "post",
		async : false,
		cache:false,
		url : urldata,
		dataType: "json",
		data: {'dataStr':dataStr},
		success : function(json){
			fuc(json.error,json["msg"],json["parameter"],retparam);
		}
	});
}
/*通用链接云中间件接口函数*/
export function esaLinkServer(commond, code, params, fuc, retparam, ishttps) {
  var inputdata = { command: commond, code: code, parameter: params };
  var data = JSON.stringify(inputdata);
  data = Base64.encode(data);
  var urldata;
  /*
    if (ishttps)
    {
        urldata = "https://localhost.easysign.cn:1991?data=";
    }else{
        urldata = "http://127.0.0.1:1990?data=";
    }
    */

  if (ishttps) {
    urldata = "https://localhost.easysign.cn:36717?data=";
  } else {
    urldata = "http://127.0.0.1:36716?data=";
  }

  /*
    if (ishttps)
    {
        urldata = "https://localhost.easysign.cn:1993?data=";
    }else{
        urldata = "http://127.0.0.1:1992?data=";
    }
    */
  urldata = urldata + data;
    console.log(urldata)
  var ajax = $.ajax({
    type: "get",
    // async : false,
    url: urldata,
    dataType: "jsonp",
    timeout: 1000,
    // jsonp : "callback",//传递给请求处理程序或页面的，用以获得jsonp回调函数名的参数名(默认为:callback)
    // //jsonpCallback : "success_jsonpCallback",//自定义的jsonp回调函数名称，默认为jQuery自动生成的随机函数名
     //jsonpCallback : "success_jsonpCallback" + Math.floor(Math.random()*100000),		//某些计算机上会出现success_jsonpCallback错误，因此加上随机数
    success: function(json) {
      console.log(json)
      fuc(json.error, json["msg"], json["parameter"], retparam);
    },
    error: function(jqXHR, textStatus) { 
      // 此时textStatus为‘timeout’ 
      console.log(jqXHR,textStatus)
      // if(jqXHR){
      //   alert("未能连接上印章卡,请确认印章卡插入!,若未下载/安装请先下载并安装后再插入签章（Ukey）")
      // }
     } 
  });
}
/*通用返回处理接口*/
export function retfunc(status, msg, retpar, params) {
  if (status == 0) {
    //成功处理
    alert("处理成功!");
    if (retpar != null) {
      alert(retpar[0]);
    }
  } else {
    alert(msg);
  }
}
