import {
    getSm2DataHexByString2,
    getSm2DataByString2
} from './Crypto'
// 请求拦截
export const requestFilter = async (config) => {
    let ignoreList = window.ignoreList || []
    let ifo = ignoreList.filter(item => item.includes(config.url))
    if (window.ifOpen && ifo.length < 1 && config.data) {
        let encryptData = await getSm2DataHexByString2(JSON.stringify(config.data), window.servicePublicKey)
        config.data = {
            encryptData: encryptData
        }
    }
}
// 响应拦截
export const responseFilter = async (response) => {
    if (response.status == 200) {
        let data = response.data.data
        if (window.ifOpen) {
            let decryptData = getSm2DataByString2(data, window.clientPrivateKey)
            try {
                response.data.data = JSON.parse(decryptData)
            } catch (e) {
                response.data.data = decryptData
            }
        }
    }
}