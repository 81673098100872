// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/logo_footer.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/zxx_img/bei/logoW.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../assets/img/qrcode1.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".bottom_img img[data-v-3de418fb]{width:5.729167rem;height:5.729167rem}.footers[data-v-3de418fb]{height:3.125rem;color:#fff;font-size:.833333rem}.footer[data-v-3de418fb],.footers[data-v-3de418fb]{width:100%;min-width:99.114583rem;background-color:#454545}.footer[data-v-3de418fb]{height:12.604167rem}.footer .footer_list[data-v-3de418fb]{width:75rem;text-align:center}.footer .footer_list .box[data-v-3de418fb]{color:#fff;height:100%}.footer .footer_list .box .image[data-v-3de418fb]{width:15.3125rem;height:3.229167rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%}.footer .footer_list .box .images[data-v-3de418fb]{width:15.3125rem;height:3.541667rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;background-size:100% 100%}.footer .footer_list .boxone[data-v-3de418fb]{display:flex;justify-content:space-between;color:#ccc}.footer .footer_list .boxone .middle[data-v-3de418fb]{margin:.520833rem 0 .520833rem 0}.footer .footer_list .boxone span+span[data-v-3de418fb]{display:inline-block;margin-left:1.041667rem;color:#ccc}.footer .footer_list .boxtow[data-v-3de418fb]{width:33.072917rem;display:flex;justify-content:space-between;align-items:center;padding-right:2.03125rem}.footer .footer_list .boxtow .weixin[data-v-3de418fb]{text-align:center}.footer .footer_list .boxtow .weixin .b[data-v-3de418fb]{margin-top:.260417rem;font-size:.729167rem;font-family:PingFang SC;font-weight:500;color:#ccc}.footer .footer_list .boxtow .qecodeone[data-v-3de418fb]{width:5.208333rem;height:5.208333rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat;background-size:100% 100%}.shou[data-v-3de418fb]{text-align:left;cursor:pointer}.footers_on[data-v-3de418fb]{display:flex;align-items:center;justify-content:center;padding-top:1.145833rem;letter-spacing:.052083rem}.marginleft10[data-v-3de418fb]{margin-left:.520833rem}", ""]);
// Exports
module.exports = exports;
