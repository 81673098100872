import store from '../store'
import * as types from '../store/action-types'
import Vue from 'vue'
export function refreshToken () {
  return store.dispatch(types.GET_TOKEN,{
    'grant_type': 'refresh_token',
    'client_id': 'xjcec',
    'client_secret': 'xjcec_secret',
    'scope': 'all',
    'refresh_token': Vue.ls.get(types.REFRESH_TOKEN)
  })
}