const sm2 = require('sm-crypto').sm2 // 获取sm2对象
const cipherMode = 0 // 选择加密策略，1 - C1C3C2，0 - C1C2C3，默认为1
// 加密
export async function getSm2DataHexByString2(str, key) {
  let data = '04' + sm2.doEncrypt(str, key, cipherMode)
  return data
}
// 解密 
export function getSm2DataByString2(dataHex, key) {
  if (dataHex && (typeof dataHex === 'string') && dataHex.constructor === String) {
    dataHex = dataHex.substring(2).toLocaleLowerCase()
    return sm2.doDecrypt(dataHex, key, cipherMode)
  }
}
// 递归取值一直拿到为止
export async function getEncryptData (str) {
  let encryptData = getSm2DataHexByString2(str)
  if (encryptData) {
    return encryptData
  } else {
    getEncryptData(str)
  }
}
