/**
 * 环境设置请根据需求修改当前目录下
 * signConfig.js 签章路径
 * signLoginConfig 签章登录路径
 */

// 主服务后台地址
exports.baseUrl = process.env.VUE_APP_BASE_URL
// 发改委地址
exports.baseUrlByDm = process.env.VUE_APP_BASE_URL_BY_DM
// 数据大屏地址
exports.baseUrlBySJDP = process.env.VUE_APP_BASE_URL_BY_SJDP
// 资讯地址
exports.baseUrlBySNEWS = process.env.VUE_APP_BASE_URL_BY_SNEWS

// vue.ls 配置
exports.storageOptions = {
  namespace: 'hy__', // key prefix
  name: 'ls', // name variable Vue.[ls] or this.[$ls],
  storage: 'local', // storage name session, local, memory 
}
