/*
	ESASignClient集成使用js
	版本：1.1
	功能列表：
	1.安全身份认证
	2.WEB签章
	3.PDF签章
 */
import * as esaLinkServer from "./esaLinkServer";
// console.log(esaLinkServer)
/*以下为身份认证接口函数*/
//检查是否插入key
export function ESACheckIsKey(func, param, ishttps) {
  var input = new Array();
  input[0] = "";
  esaLinkServer.esaLinkServer(113, 0, input, func, param, ishttps);
}
//检查Key口令
export function ESAVerifyPin(func, param, ishttps) {
  var input = new Array();
  input[0] = "";
  esaLinkServer.esaLinkServer(113, 1, input, func, param, ishttps);
}

//检查Key口令传入密码
export function ESAVerifyPinUI(kpin, func, param, ishttps) {
  var input = new Array();
  input[0] = kpin.toString();
  esaLinkServer.esaLinkServer(114, 4, input, func, param, ishttps);
}

//写入用户文件数据
export function ESAWriteFile(filedata, func, param, ishttps) {
  var input = new Array();
  input[0] = filedata.toString();
  esaLinkServer.esaLinkServer(113, 2, input, func, param, ishttps);
}
//读取用户文件数据
export function ESAReadFile(func, param, ishttps) {
  var input = new Array();
  input[0] = "";
  esaLinkServer.esaLinkServer(113, 3, input, func, param, ishttps);
}
//删除用户文件数据
export function ESADeleteFile(func, param, ishttps) {
  var input = new Array();
  input[0] = "";
  esaLinkServer.esaLinkServer(113, 4, input, func, param, ishttps);
}
//获取证书绑定的用户名
export function ESAGetUserName(func, param, ishttps) {
  var input = new Array();
  input[0] = "";
  esaLinkServer.esaLinkServer(113, 5, input, func, param, ishttps);
}
//验证用户
export function ESAVerifyUser(username, func, param, ishttps) {
  var input = new Array();
  input[0] = username.toString();
  esaLinkServer.esaLinkServer(113, 6, input, func, param, ishttps);
}

//云平台身份认证登录
export function ESAVerifyIdenCloud(
  url,
  username,
  pass,
  serial,
  version,
  func,
  param,
  ishttps
) {
  var input = new Array();
  input[0] = version.toString();
  input[1] = url.toString();
  input[2] = username.toString();
  input[3] = pass.toString();
  input[4] = serial.toString();

  esaLinkServer.esaLinkServer(113, 7, input, func, param, ishttps);
}

//手机盾申请证书接口
export function ESAShiledApplyCer(
  version,
  url,
  accout,
  pass,
  serial,
  cerType,
  cerAuthName,
  certAuthId,
  userId,
  CertAlgo,
  Years,
  AccessPw,
  func,
  param,
  ishttps
) {
  var input = new Array();
  input[0] = version.toString();
  input[1] = url.toString();
  input[2] = accout.toString();
  input[3] = pass.toString();
  input[4] = serial.toString();
  input[5] = cerType.toString();
  input[6] = cerAuthName.toString();
  input[7] = certAuthId.toString();
  input[8] = userId.toString();
  input[9] = CertAlgo.toString();
  input[10] = Years.toString();
  input[11] = AccessPw.toString();
  esaLinkServer.esaLinkServer(113, 8, input, func, param, ishttps);
}

//手机盾获取证书
export function ESAShiledGetCer(
  version,
  url,
  accout,
  pass,
  userId,
  AccessPw,
  func,
  param,
  ishttps
) {
  var input = new Array();
  input[0] = version.toString();
  input[1] = url.toString();
  input[2] = accout.toString();
  input[3] = pass.toString();
  input[4] = userId.toString();
  input[5] = AccessPw.toString();

  esaLinkServer.esaLinkServer(113, 9, input, func, param, ishttps);
}

//手机盾签名
export function ESAShiledSign(
  version,
  url,
  accout,
  pass,
  userId,
  content,
  AccessPw,
  func,
  param,
  ishttps
) {
  var input = new Array();
  input[0] = version.toString();
  input[1] = url.toString();
  input[2] = accout.toString();
  input[3] = pass.toString();
  input[4] = userId.toString();
  input[5] = content.toString();
  input[6] = AccessPw.toString();

  esaLinkServer.esaLinkServer(113, 10, input, func, param, ishttps);
}

//手机盾签名扩展传hash算法
export function ESAShiledSignEx(
  version,
  url,
  accout,
  pass,
  userId,
  content,
  AccessPw,
  func,
  param,
  ishttps
) {
  var input = new Array();
  input[0] = version.toString();
  input[1] = url.toString();
  input[2] = accout.toString();
  input[3] = pass.toString();
  input[4] = userId.toString();
  input[5] = content.toString();
  input[6] = AccessPw.toString();

  esaLinkServer.esaLinkServer(113, 14, input, func, param, ishttps);
}

//手机盾获取设备信息
export function ESAShiledDevInfo(func, param, ishttps) {
  var input = new Array();
  input[0] = "";
  esaLinkServer.esaLinkServer(113, 11, input, func, param, ishttps);
}

/*以下为网页签章接口函数*/
//网页签章
export function WebStartSign(
  sealType,
  SignData,
  filename,
  sealcode,
  func,
  param,
  ishttps
) {
  var input = new Array();
  input[0] = sealType.toString();
  input[1] = SignData.toString();
  input[2] = filename.toString();
  input[3] = sealcode.toString();
  esaLinkServer.esaLinkServer(101, 12, input, func, param, ishttps);
}
//获取网页签章中的属性
export function WebGetAttr(xmldata, attr, func, param, ishttps) {
  var input = new Array();
  input[0] = xmldata.toString();
  input[1] = attr.toString();
  esaLinkServer.esaLinkServerPost(101, 13, input, func, param, ishttps);
}
//验证网页签章是否有效
export function WebVerify(xmldata, verifyData, func, param, ishttps) {
  var input = new Array();
  input[0] = xmldata.toString();
  input[1] = verifyData.toString();
  esaLinkServer.esaLinkServerPost(101, 14, input, func, param, ishttps);
}
//显示证书
export function WebShowCert(xmldata, state, func, param, ishttps) {
  var input = new Array();
  input[0] = xmldata.toString();
  input[1] = state.toString();
  esaLinkServer.esaLinkServerPost(101, 15, input, func, param, ishttps);
}
//删除印章
export function WebDelSign(xmldata, state, func, param, ishttps) {
  var input = new Array();
  input[0] = xmldata.toString();
  input[1] = state.toString();
  esaLinkServer.esaLinkServerPost(101, 20, input, func, param, ishttps);
}
//网页加密与解密
export function WebEnAndDecrypt(xmldata, state, func, param, ishttps) {
  var input = new Array();
  input[0] = xmldata.toString();
  input[1] = state.toString();
  esaLinkServer.esaLinkServerPost(101, 21, input, func, param, ishttps);
}

//获取卡证书在服务端数据
export function BaseGetData(type, func, param, ishttps) {
  var input = new Array();
  input[0] = type.toString();
  esaLinkServer.esaLinkServer(101, 22, input, func, param, ishttps);
}

/*PDF后台云签名接口-只能单个签*/
//云签中间件地址,签章类型,是否缓存印章,指令参数,回调函数,业务系统输入参数(回调会传递回来),是否为HTTPS协议
export function PDFSignCloud(
  Servlet,
  SealType,
  Reserved,
  SignKeyWord,
  func,
  param,
  ishttps
) {
  var input = new Array();
  input[0] = Servlet.toString();
  input[1] = SealType.toString();
  input[2] = Reserved.toString();
  input[3] = SignKeyWord.toString();
  esaLinkServer.esaLinkServer(101, 3, input, func, param, ishttps);
}

/*PDF后台云签名接口-支持多文件名称*/
//云签中间件地址,签章类型,是否缓存印章,指令参数,回调函数,业务系统输入参数(回调会传递回来),是否为HTTPS协议
export function PDFSignCloudThread(
  Servlet,
  SealType,
  Reserved,
  SignKeyWord,
  func,
  param,
  ishttps
) {
  var input = new Array();
  input[0] = Servlet.toString();
  input[1] = SealType.toString();
  input[2] = Reserved.toString();
  input[3] = SignKeyWord.toString();
  esaLinkServer.esaLinkServer(101, 17, input, func, param, ishttps);
}

//清除缓存印章数据
//回调函数,业务系统输入参数(回调会传递回来),是否为HTTPS协议
export function PDFSignFreeCloud(func, param, ishttps) {
  esaLinkServer.esaLinkServer(101, 4, "", func, param, ishttps);
}

//使用本地图片文件进行PDF云签
export function PDFSignByImg(
  Servlet,
  SealType,
  Reserved,
  SignKeyWord,
  filePath,
  func,
  param,
  ishttps
) {
  var input = new Array();
  input[0] = Servlet.toString();
  input[1] = SealType.toString();
  input[2] = Reserved.toString();
  input[3] = SignKeyWord.toString();
  input[4] = filePath.toString();
  esaLinkServer.esaLinkServer(101, 7, input, func, param, ishttps);
}

//后台删除PDF文档印章
export function PDFRevokedSign(
  Servlet,
  Filepath,
  Fieldid,
  func,
  param,
  ishttps
) {
  var input = new Array();
  input[0] = Servlet.toString();
  input[1] = Filepath.toString();
  input[2] = Fieldid.toString();
  esaLinkServer.esaLinkServer(101, 8, input, func, param, ishttps);
}

//后台PDF签章设置系统授权码和日志ID
export function PDFSetReg(Appcode, NoteId, func, param, ishttps) {
  var input = new Array();
  input[0] = Appcode.toString();
  input[1] = NoteId.toString();
  esaLinkServer.esaLinkServer(101, 11, input, func, param, ishttps);
}

//PDF云打印(通过在线控件打印文件)
export function PDFPrintCloud(lpFile, lpRang, lpcopies, func, param, ishttps) {
  var input = new Array();
  input[0] = lpFile.toString();
  input[1] = lpRang.toString();
  input[2] = lpcopies.toString();
  esaLinkServer.esaLinkServer(101, 16, input, func, param, ishttps);
}

//PDF云签章(通过在线控件对PDF签名)
export function PDFOcxSignCloud(
  lpFileData,
  lpPwd,
  lpSignType,
  lpImgInfo,
  lpcopies,
  lpSignParam,
  func,
  param,
  ishttps
) {
  var input = new Array();
  input[0] = lpFileData.toString();
  input[1] = lpPwd.toString();
  //印章来源
  input[2] = lpSignType.toString();
  //印章图片数据或者印章特性码
  input[3] = lpImgInfo.toString();
  //是否使用缓存印章图片
  input[4] = lpcopies.toString();
  //定位参数
  input[5] = lpSignParam.toString();
  esaLinkServer.esaLinkServerPost(101, 24, input, func, param, ishttps);
}

//PDF云验证（通过在线控件对PDF验证）
export function PDFOcxVerifyCloud(lpFileData, lpPwd, func, param, ishttps) {
  var input = new Array();
  input[0] = lpFileData.toString();
  input[1] = lpPwd.toString();
  esaLinkServer.esaLinkServerPost(101, 25, input, func, param, ishttps);
}

/* 以下为其他签章接口 */
//获取印章个数
export function BaseGetSealCount(SealType, func, param, ishttps) {
  var input = new Array();
  input[0] = SealType.toString();
  esaLinkServer.esaLinkServer(101, 10, input, func, param, ishttps);
}

//获取印模图片及印模尺寸
export function BaseGetSeal(sealType, func, param, ishttps) {
  var input = new Array();
  input[0] = sealType.toString();
  esaLinkServer.esaLinkServer(101, 0, input, func, param, ishttps);
}

//对数据签名（固定哈希算法SHA1）
export function BaseSignFixed(hashdata, func, param, ishttps) {
  var input = new Array();
  input[0] = hashdata.toString();
  esaLinkServer.esaLinkServer(101, 1, input, func, param, ishttps);
}

//对数据签名(外部传入Hash算法及数据)
//hashType 1MD5 2SHA1 3SHA256 4SM3
export function BaseSignByHash(hashdata, hashType, func, param, ishttps) {
  var input = new Array();
  input[0] = hashdata.toString();
  input[1] = hashType.toString();
  esaLinkServer.esaLinkServer(101, 2, input, func, param, ishttps);
}

//对数据签名(外部传入Hash算法及数据 生成原始签名值)
//hashType 1MD5 2SHA1 3SHA256 4SM3
export function BaseSignByHashEx(hashdata, hashType, func, param, ishttps) {
  var input = new Array();
  input[0] = hashdata.toString();
  input[1] = hashType.toString();
  esaLinkServer.esaLinkServer(101, 26, input, func, param, ishttps);
}

//对数据签名生成pkcs7(外部传入Hash算法及数据)
//hashType 1MD5 2SHA1 3SHA256 4SM3
export function BasePkcs7Sign(sourceData, hashType, func, param, ishttps) {
  var input = new Array();
  input[0] = sourceData.toString();
  input[1] = hashType.toString();
  esaLinkServer.esaLinkServerPost(113, 15, input, func, param, ishttps);
}

//外部传入所有摘要值，程序根据支持的摘要算法选择摘要值签名
export function BaseSignByAllHash(hashdata, func, param, ishttps) {
  var input = new Array();
  input[0] = hashdata.toString();
  esaLinkServer.esaLinkServer(101, 19, input, func, param, ishttps);
}

//获取证书
//CertType 1签名 2加密
export function BaseGetCert(CertType, func, param, ishttps) {
  var input = new Array();
  input[0] = CertType.toString();
  esaLinkServer.esaLinkServer(101, 5, input, func, param, ishttps);
}

//获取证书详细信息
//CertInfoFlg 1证书cn  2证书序列号  3证书有效期  4证书公钥数据
export function BaseGetCertSub(CertData, CertInfoFlg, func, param, ishttps) {
  var input = new Array();
  input[0] = CertData.toString();
  input[1] = CertInfoFlg.toString();
  esaLinkServer.esaLinkServer(113, 12, input, func, param, ishttps);
}

//获取设备信息（设备名称,IP,MAC）
export function BaseGetDevInfo(func, param, ishttps) {
  var input = new Array();
  input[0] = "";
  esaLinkServer.esaLinkServer(113, 13, input, func, param, ishttps);
}

//获取Ukey的序列号
export function BaseGetKeyID(func, param, ishttps) {
  var input = new Array();
  input[0] = "";
  esaLinkServer.esaLinkServer(113, 16, input, func, param, ishttps);
}

//对本地文件(数据)进行签名
export function BaseSignByFile(filepath, func, param, ishttps) {
  var input = new Array();
  input[0] = filepath.toString();
  esaLinkServer.esaLinkServer(101, 6, input, func, param, ishttps);
}

//对授权码，日志码，印章特性码设置
export function BaseSetRegEx(
  appcode,
  notecode,
  sealcode,
  func,
  param,
  ishttps
) {
  var input = new Array();
  input[0] = appcode.toString();
  input[1] = notecode.toString();
  input[2] = sealcode.toString();
  esaLinkServer.esaLinkServer(114, 0, input, func, param, ishttps);
}

//对服务进行配置
export function BaseSetSer(seraddr, servlet, proxy, func, param, ishttps) {
  var input = new Array();
  input[0] = seraddr.toString();
  input[1] = servlet.toString();
  input[2] = proxy.toString();
  esaLinkServer.esaLinkServer(114, 1, input, func, param, ishttps);
}

//对Ukey用户口令使用管理员口令解锁
export function BaseUnLockPin(SoPin, UserPin, func, param, ishttps) {
  var input = new Array();
  input[0] = SoPin.toString();
  input[1] = UserPin.toString();
  esaLinkServer.esaLinkServer(114, 2, input, func, param, ishttps);
}

//清除密码
export function BaseClearPin(func, param, ishttps) {
  var input = new Array();
  input[0] = "";
  esaLinkServer.esaLinkServer(114, 3, input, func, param, ishttps);
}

//传入密码验证
export function BaseVerifyNoUI(pin, func, param, ishttps) {
  var input = new Array();
  input[0] = pin.toString();
  esaLinkServer.esaLinkServer(114, 4, input, func, param, ishttps);
}

/*以下为CA发证接口*/
//返回当前插入key的csp或者dll名称
export function CAEnumName(type, func, param, ishttps) {
  var input = new Array();
  input[0] = type.toString();
  esaLinkServer.esaLinkServer(111, 1, input, func, param, ishttps);
}

export function CADelCont(cspname, type, func, param, ishttps) {
  var input = new Array();
  input[0] = cspname.toString();
  input[1] = type.toString();
  esaLinkServer.esaLinkServer(111, 2, input, func, param, ishttps);
}

//产生p1010:18
export function CACreateP10(xml, func, param, ishttps) {
  var input = new Array();
  input[0] = xml.toString();
  esaLinkServer.esaLinkServer(111, 3, input, func, param, ishttps);
}

//安装证书
export function CAInstallCer(
  cspname,
  contname,
  Cer,
  pfx,
  pfxpass,
  keyType,
  func,
  param,
  ishttps
) {
  var input = new Array();
  input[0] = cspname.toString();
  input[1] = contname.toString();
  input[2] = Cer.toString();
  input[3] = pfx.toString();
  input[4] = pfxpass.toString();
  input[5] = keyType.toString();
  esaLinkServer.esaLinkServer(111, 4, input, func, param, ishttps);
}

//获取Key中证书
export function CAGetCert(cspname, bSign, keyType, func, param, ishttps) {
  var input = new Array();
  input[0] = cspname.toString();
  input[1] = bSign.toString();
  input[2] = keyType.toString();
  esaLinkServer.esaLinkServer(111, 5, input, func, param, ishttps);
}

/*OFD后台云签名接口-支持多文件名称*/
//单个OFD文档签章坐标
export function OFDSignSingle(
  servlet,
  sealtype,
  filepath,
  signpage,
  signx,
  signy,
  postion,
  locattype,
  QrStr,
  func,
  param,
  ishttps
) {
  var input = new Array();
  input[0] = servlet.toString();
  input[1] = sealtype.toString();
  input[2] = filepath.toString();
  input[3] = signpage.toString();
  input[4] = signx.toString();
  input[5] = signy.toString();
  input[6] = postion.toString();
  input[7] = locattype.toString();
  input[8] = QrStr.toString();

  esaLinkServer.esaLinkServer(115, 0, input, func, param, ishttps);
}

//OFD签章释放
export function OFDSignFree(func, param, ishttps) {
  esaLinkServer.esaLinkServer(115, 1, "", func, param, ishttps);
}

//OFD多文档签章(坐标)
export function OFDSignLot(
  servlet,
  sealtype,
  filepath,
  signpage,
  signx,
  signy,
  postion,
  locattype,
  QrStr,
  Account,
  AppKey,
  Prikey,
  EncryptKey,
  func,
  param,
  ishttps
) {
  var input = new Array();
  input[0] = servlet.toString();
  input[1] = sealtype.toString();
  input[2] = filepath.toString();
  input[3] = signpage.toString();
  input[4] = signx.toString();
  input[5] = signy.toString();
  input[6] = postion.toString();
  input[7] = locattype.toString();
  input[8] = QrStr.toString();
  if (Account) input[9] = Account.toString();
  if (AppKey) input[10] = AppKey.toString();
  if (Prikey) input[11] = Prikey.toString();
  if (EncryptKey) input[12] = EncryptKey.toString();
  esaLinkServer.esaLinkServer(115, 2, input, func, param, ishttps);
}

//OFD会签功能
export function OFDSignLotXml(
  servlet,
  InFile,
  OutFile,
  SignData,
  type,
  func,
  param,
  ishttps
) {
  var input = new Array();
  input[0] = servlet.toString();
  input[1] = InFile.toString();
  input[2] = OutFile.toString();
  input[3] = SignData.toString();
  input[4] = type.toString();
  esaLinkServer.esaLinkServerPost(115, 3, input, func, param, ishttps);
}

//OFD会签删除印章功能
export function OFDRemoveLotXml(
  servlet,
  InFile,
  OutFile,
  ID,
  func,
  param,
  ishttps
) {
  var input = new Array();
  input[0] = servlet.toString();
  input[1] = InFile.toString();
  input[2] = OutFile.toString();
  input[3] = ID.toString();
  esaLinkServer.esaLinkServerPost(115, 4, input, func, param, ishttps);
}

//OFD批量签章通过关键字
export function OFDSignLotByKeyWords(
  servlet,
  sealtype,
  filepath,
  signKeyWord,
  signx,
  signy,
  signIndex,
  QrStr,
  func,
  param,
  ishttps
) {
  var input = new Array();
  input[0] = servlet.toString();
  input[1] = sealtype.toString();
  input[2] = filepath.toString();
  input[3] = signKeyWord.toString();
  input[4] = signx.toString();
  input[5] = signy.toString();
  input[6] = signIndex.toString();
  input[7] = QrStr.toString();
  esaLinkServer.esaLinkServer(115, 5, input, func, param, ishttps);
}

//OFD批量签骑缝章
export function OFDSignLotBetWeen(
  servlet,
  sealtype,
  filepath,
  CutCount,
  MuSealType,
  SealWay,
  signx,
  signy,
  QrStr,
  func,
  param,
  ishttps
) {
  var input = new Array();
  input[0] = servlet.toString();
  input[1] = sealtype.toString();
  input[2] = filepath.toString();
  input[3] = CutCount.toString();
  input[4] = MuSealType.toString();
  input[5] = SealWay.toString();
  input[6] = signx.toString();
  input[7] = signy.toString();
  input[8] = QrStr.toString();
  esaLinkServer.esaLinkServer(115, 6, input, func, param, ishttps);
}

//OFD获取印章列表跟图片
export function OFDSignLotList(func, param, ishttps) {
  esaLinkServer.esaLinkServer(115, 7, "", func, param, ishttps);
}

//OFD对数据进行签名
export function OFDSignTheData(toSignData, func, param, ishttps) {
  var input = new Array();
  input[0] = toSignData.toString();
  esaLinkServer.esaLinkServer(115, 8, input, func, param, ishttps);
}

//私钥解密(国密接口)DnType 1加密 2签名
export function PrvKeyDecrypt(DnType, endata, func, param, ishttps) {
  var input = new Array();
  input[0] = DnType.toString();
  input[1] = endata.toString();
  esaLinkServer.esaLinkServer(188, 6, input, func, param, ishttps);
}

//获取外接手写设备的签名图片数据
export function BaseGethandSignSeal(handSignFlg, func, param, ishttps) {
  var input = new Array();
  input[0] = handSignFlg.toString();
  esaLinkServer.esaLinkServer(101, 28, input, func, param, ishttps);
}

/*PDF后台云签名接口-支持多印章多位置多文件名称*/
//云签中间件地址,指令参数,回调函数,业务系统输入参数(回调会传递回来),是否为HTTPS协议
export function PDFMoreSignCloudThread(
  Servlet,
  SignKeyWord,
  func,
  param,
  ishttps
) {
  var input = new Array();
  input[0] = Servlet.toString();
  input[1] = SignKeyWord.toString();
  esaLinkServer.esaLinkServer(101, 29, input, func, param, ishttps);
}

//获取印章特性码等相关信息
export function BaseGetSealCodeByUkey(func, param, ishttps) {
  esaLinkServer.esaLinkServer(101, 30, "", func, param, ishttps);
}

//重置用户ukey密码
export function ESAResetPin(url, token, data, func, param, ishttps) {
  var input = new Array();
  input[0] = url.toString();
  input[1] = token.toString();
  input[2] = data.toString();
  esaLinkServer.esaLinkServer(113, 17, input, func, param, ishttps);
}

//获取UKEY的CSP名称
export function ESAGetUkeyCSPName(func, param, ishttps) {
  esaLinkServer.esaLinkServer(101, 31, "", func, param, ishttps);
}

//获取手写屏设备身份信息
export function ESAGetIDCARDBYHandWritingScreen(func, param, ishttps) {
  esaLinkServer.esaLinkServer(101, 32, "", func, param, ishttps);
}
