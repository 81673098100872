<template>
  <div id="app" v-if="!isH5Page">
    <div class="header_flx">
      <headerNav :key="key" />
    </div>
    <div
      v-show="$route.path !== '/login'"
      style="width: 100%; height: 170px"
      :style="{height: $route.path == '/priceIndex'?`160px`: '170px'}"
    ></div>
    <div
      v-show="$route.path == '/login'"
      style="width: 100%; height: 92px"
    ></div>
    <div class="app-contanier">
      <Breadcrumb></Breadcrumb>

      <!-- <keep-alive>
        <router-view v-if="$route.meta.keepAlive" class="router-view">
        </router-view>
      </keep-alive>
      <router-view
        v-if="!$route.meta.keepAlive"
        style="min-height: calc(100% - 450px)"
      /> -->
      <router-view style="min-height: calc(100% - 450px)" />
    </div>
    <footerNav />
    <div class="calculator" @click="goProfit" v-if="profitIsShow">
      <!-- <p>利润</p>
      <p>计算</p> -->
      <img src="../src/assets/zxx_img/KHCFDC.png" alt="" />
    </div>
    <div class="qr_code" @mouseover="over" @mouseout="out">
      <i class="hy-icon-31erweima" style="font-size: 20px"></i>
    </div>
    <div id="qr_code_img">
      <div class="background"></div>
      <div class="qr_code_title">关注新疆煤炭</div>
      <div>轻松了解煤炭信息</div>
    </div>
    <div class="backtop">
      <el-backtop></el-backtop>
    </div>
  </div>
  <div id="app" v-else>
    <router-view></router-view>
  </div>
</template>

<script>
import header from "./layout/Header.vue";
import footer from "./layout/Footer.vue";
import { getUuid } from "@/utils/uuid";
import { getqxshuju } from "@/api/home";
import htmlFontSize from "@/utils/htmlFontSize.js";
export default {
  components: {
    headerNav: header,
    footerNav: footer,
    Breadcrumb: () => import("@/layout/Breadcrumb"),
  },
  name: "App",
  mixins: [htmlFontSize],
  data() {
    return {
      profitIsShow: false,
      isH5Page: false,
    };
  },
  created() {
    let isH5Page = ["/freighth5", "/freighth5details"];
    this.isH5Page = isH5Page.includes(
      location.hash.slice(
        location.hash.indexOf("#") + 1,
        location.hash.indexOf("?") == -1
          ? location.hash.length
          : location.hash.indexOf("?")
      )
    );
  },
  mounted() {
    if (!this.isH5Page) {
      this.jurisdiction();
    }
  },
  computed: {
    key() {
      let num =
        this.$route.name !== undefined
          ? this.$route.name + getUuid()
          : this.$route + getUuid();
      return num;
    },
  },
  watch: {
    $route(to, from) {
      let profitShowList = [
        "/index",
        "/mall",
        "/addGoods",
        "/addBiddingGoods",
        "/goodsDetail",
      ];
      this.profitIsShow = profitShowList.includes(to.path);

      let isH5Page = ["/freighth5", "/freighth5details"];
      this.isH5Page = isH5Page.includes(to.path);
      this.jurisdiction1();
    },
  },
  methods: {
    jurisdiction1() {
      this.$store.commit("user/changeMassage", "");
      let token = window.localStorage.getItem("token");
      if (!token) {
        this.$store.commit("user/changeMassage", "未登录请前往登录");
      } else {
        this.everything1();
      }
    },
    jurisdiction() {
      window.localStorage.setItem("massage", "");
      this.$store.commit("user/changeMassage", "");
      let token = window.localStorage.getItem("token");
      if (!token) {
        window.localStorage.setItem("massage", "未登录请前往登录");

        this.$store.commit("user/changeMassage", "未登录请前往登录");
      } else {
        this.everything();
      }
    },
    async everything1() {
      await getqxshuju().then((res) => {
        if (res.code == 0) {
          if (res.data) {
            let a = res.data.auditStatus == -1 || res.data.auditStatus == 2;
            let b =
              res.data.mineAuditStatus == -1 || res.data.mineAuditStatus == 2;
            let massage =
              res.data.auditStatus == 1 || res.data.auditStatus == 1;
            this.$store.commit(
              "user/changeMassage",
              massage ? "" : "请前往认证后操作"
            );
          } else {
            this.$store.commit("user/changeMassage", "请前往认证后操作");
          }
        }
      });
    },
    async everything() {
      await getqxshuju().then((res) => {
        if (res.code == 0) {
          if (res.data) {
            let a = res.data.auditStatus == -1 || res.data.auditStatus == 2;
            let b =
              res.data.mineAuditStatus == -1 || res.data.mineAuditStatus == 2;
            window.localStorage.setItem("userName", res.data.companyName);

            let massage =
              res.data.auditStatus == 1 || res.data.auditStatus == 1;
            this.$store.commit(
              "user/changeMassage",
              massage ? "" : "请前往认证后操作"
            );

            // if (res.data.auditStatus == 1 || res.data.mineAuditStatus == 1) {
            //    return '';
            // } else if (
            //   res.data.auditStatus == -1 ||
            //   res.data.auditStatus == 2 ||
            //   res.data.mineAuditStatus == -1 ||
            //   res.data.mineAuditStatus == 2
            // ) {
            // return  window.localStorage.setItem("massage", "请前往认证后操作");
            // } else if (res.data.auditStatus == 0 || res.data.auditStatus == 0) {
            //   return window.localStorage.setItem(
            //     "massage",
            //     "您的企业信息正在审核中请耐心等待"
            //   );
            // }

            // res.data.auditStatus !== 1 &&
            // res.data.auditStatus !== -1 &&
            // res.data.auditStatus !== 2&&res.data.mineAuditStatus !== 1 &&
            // res.data.mineAuditStatus !== -1 &&
            // res.data.mineAuditStatus !== 2
            //   ? window.localStorage.setItem(
            //       "massage",
            //       "您的企业信息正在审核中请耐心等待"
            //     )
            //   : a||b
            //   ? window.localStorage.setItem("massage", "请前往认证后操作")
            //   : "";
          } else {
            window.localStorage.setItem("massage", "请前往认证后操作");
            this.$store.commit("user/changeMassage", "请前往认证后操作");
          }
        }
      });
    },
    goProfit() {
      this.$router.push({
        path: "/profit",
      });
    },
    over() {
      document.getElementById("qr_code_img").style.display = "block";
    },
    out() {
      document.getElementById("qr_code_img").style.display = "none";
    },
  },
};
</script>

<style lang="scss">
@import "./assets/style/base.scss";

#app {
  // width: 1920px;
  height: 100%;
}
body {
  // background: white;
}
.box-shadow {
  box-shadow: none;
}
.header_flx {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}
.qr_code {
  cursor: pointer;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  right: 25px;
  bottom: 80px;
  background: #ffffff;
  box-shadow: 0 0 6px rgb(0 0 0 / 12%);
}
.backtop {
  .el-backtop {
    color: #666666;
    position: fixed;
    display: flex;
    right: 25px !important;
    bottom: 130px !important;
  }
}
.qr_code:hover {
  background-color: #f2f6fc;
}
#qr_code_img {
  position: fixed;
  display: none;
  width: 144px;
  height: 182px;
  right: 84px;
  bottom: 77px;
  padding: 16px 16px 0;
  background-color: #ffffff;
  box-shadow: 0px 3px 18px 2px rgb(0 0 0 / 5%);
  text-align: center;
  // transition: all 1s;
  .background {
    width: 113px;
    height: 113px;
    background: url("./assets/img/qrcode1.png") no-repeat;
    background-size: 100% 100%;
  }
  .qr_code_title {
    margin-top: 4px;
  }
}
.calculator {
  position: fixed;
  right: 25px;
  bottom: 30px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  box-shadow: 0px 0px 2px #cecece;
  background: white;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  img {
    margin-top: 8px;
    width: 20px;
    height: 22px;
  }
}
.breadcrumb {
  max-width: 1200px;
  margin: 0 auto;
}
.app-contanier {
  width: 100%;
  position: relative;
  min-height: calc(100% - 400px);
  &::before,
  &::after {
    content: "";
    display: table;
  }
}
</style>
