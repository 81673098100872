export default {
  data() {
    return {
      resizeTimer: null,
    };
  },
  created() {
    this.initFontSize();
    window.addEventListener("resize", () => {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(() => {
        this.initFontSize();
      }, 50);
    });
  },
  methods: {
    initFontSize() {
      let width =
        document.documentElement.clientWidth || document.body.clientWidth;
      if (
        navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
      ) {
        width = 1920;
      }
      let isH5Page = ["/freighth5", "/freighth5details"].includes(
        location.hash.slice(
          location.hash.indexOf("#") + 1,
          location.hash.indexOf("?") == -1
            ? location.hash.length
            : location.hash.indexOf("?")
        )
      );
      let rem = 0;
      if (isH5Page) {
        rem = Number(width / 100).toFixed(4);
      } else {
        rem = width < 1280 ? 12.8 : Number(width / 100).toFixed(4);
      }
      document.querySelector("html").style.fontSize = `${rem}px`;
    },
  },
};
