const {
  baseUrl
} = require('@/config')
let {
  isProxy
} = require('@/config/proxyConfig.js')
import {
  ACCESS_TOKEN,
  EXPIRES_IN,
  IF_OPEN, ignoreList
} from "@/store/action-types"
import axios from 'axios'
import Vue from 'vue'
import router from '@/router'
import {
  Message
} from 'element-ui'
import {
  refreshToken
} from './oauth'
import to from './awaitTo.js'
import {
  requestFilter,
  responseFilter
} from '@/utils/requestFilter'
window.tokenLock = false
let cacheRequestArr = []

function isTokenExpired() {
  let expiresTimeStamp = new Date().getTime() - Vue.ls.get(EXPIRES_IN)
  return expiresTimeStamp < 60 * 30 * 1000 && expiresTimeStamp > 0
  // return 200
}
class HttpRequest {
  constructor() {
    this.baseUrl = process.env.NODE_ENV === 'development' && isProxy ? '' : baseUrl
    // this.baseUrl = 'http://192.168.0.24:9999'
    this.timeOut = 15000
  }
  setInterceptors(instance) {
    instance.interceptors.request.use(
      async config => {
        const token = Vue.ls.get(ACCESS_TOKEN)
        if (token) {
          config.headers['Xjcec-Auth'] = Vue.ls.get(ACCESS_TOKEN) // 让每个请求携带自定义 token 请根据实际情况自行修改
        }
        config.headers["client-type"] = "trade_front"; // 前台区分系统
        //接口不从缓存中取数据
        config.headers["If-Modified-Since"] = "0"
        config.headers["Cache-Control"] = "no-cache"
        // if (isTokenExpired() && !config.url.includes('/uauth/auth/getToken')) {
        //   // debugger
        //   if (!window.tokenLock) {
        //     window.tokenLock = true
        //     const [ret, err] = await to(refreshToken())
        //     if (err) return console.error('刷新token失败', err)
        //     if (ret) {
        //       config.headers['Xjcec-Auth'] = Vue.ls.get(ACCESS_TOKEN)
        //       cacheRequestArr.forEach(item => item(Vue.ls.get(ACCESS_TOKEN)))
        //       cacheRequestArr = []
        //     }
        //     window.tokenLock = false
        //   } else {
        //     return new Promise(resolve => {
        //       cacheRequestArr.push((token) => {
        //         config.headers['Xjcec-Auth'] = token
        //         resolve(config)
        //       })
        //     })
        //   }
        // }
        requestFilter(config)
        return config
      },
      err => {
        return Promise.reject(err)
      }
    )
    instance.interceptors.response.use(
      res => {
        responseFilter(res)
        if (res.status == 200) {
          return Promise.resolve(res.data)
        } else {
          return Promise.reject(res.data)
        }
      },
      error => {
        switch (error.response.status) {
          case '403':
            break
          case 401:
            router.push({
              path: '/index'
            })
            localStorage.clear();
            Message.error(error.response.data.message)
            break
          case 500:
            localStorage.clear();
            router.push({
              path: '/index'
            })
            Message.error(error.response.data.message)
            break
          default:
            break
        }
        return Promise.reject(error)
      }
    )
  }
  mergetOptions(options) {
    return {
      baseURL: this.baseUrl,
      timeout: this.timeOut,
      ...options
    }
  }
  async request(options) {
    const instance = axios.create()
    this.setInterceptors(instance)
    const opts = this.mergetOptions(options)
    return instance(opts)
  }
  get(url, config) {
    return this.request({
      method: 'get',
      url,
      ...config
    })
  }
  getquery(url, params) {
    return this.request({
      method: 'get',
      url,
      params,
    })
  }
  post(url, data) {
    return this.request({
      method: 'post',
      url,
      data: data,
      timeout: 60000000
    })
  }
  postquery(url, params) {
    return this.request({
      method: 'post',
      url,
      params
    })
  }
  postquerys(url, data) {
    return this.request({
      responseType: 'blob',
      method: 'post',
      url,
      data
    })
  }
  posttimeout(url, data) {
    return this.request({
      method: "post",
      url,
      data: data,
      timeout: 60000000
    });
  }
}
export default new HttpRequest