import { reqGetToekn } from "@/api/public";
import * as types from "./action-types";
import Vue from "vue";
import to from '../utils/awaitTo'
export default {
  state: {
    token: "",
    refreshToken: "",
  },
  mutations: {
    [types.ACCESS_TOKEN](state, payload) {
      state.token = payload;
    },
    [types.REFRESH_TOKEN](state, payload) {
      state.refreshToken = payload;
    },
  },
  actions: {
    async [types.GET_TOKEN]({ commit},data) {
      let [ret, err] = await to(reqGetToekn(data))
      // debugger
      console.log(ret)
      if (err) throw new Error("getToken is failed"); 
      let {
        data: {
          accessToken: accessToken,
          refreshToken: refreshToken,
          expiresTime: expiresTime,
          userId: userId,
          telephone: telephone,
        },
      } = ret
      Vue.ls.set(types.userId, userId)
      Vue.ls.set(types.telephone, telephone)
      Vue.ls.set(types.ACCESS_TOKEN, accessToken);
      Vue.ls.set(types.REFRESH_TOKEN, refreshToken);
      Vue.ls.set(types.EXPIRES_IN, expiresTime+ '000')
      commit(types.ACCESS_TOKEN, accessToken);
      commit(types.REFRESH_TOKEN, refreshToken);
      return true
      // dispatch(`user/${types.GET_USERINFO}`);
    },
  },
};
