export default [{
		path: "/",
		redirect: "/index",
	},
	{
		path: "/",
		component: {
			template: `<router-view></router-view>`,
		},
		children: [{
				path: "/index",
				component: () =>
					import("@/page/index/index"),
				meta: {
					title: "首页",
				},
			},
			{
				path: '/announcement',
				component: () => import('@/page/announcement/index'),
				meta: {
					title: '公告',
					breadcrumbList: [{
						name: '首页',
						path: '/'
					}]
				}
			},
			{
				path: '/biddingdeatil',
				component: () => import('@/page/biddingdeatil/index'),
				meta: {
					title: '公告详情',
					breadcrumbList: [{
						name: '首页',
						path: '/'
					}, {
						name: '公告',
						path: '/announcement'
					}]
				}
			},
		]
	}, {
		path: "/login",
		name: "login",
		component: () =>
			import("@/page/login/login"),
	},
	// 个人中心相关路由
	// 1.0 个人中心首页
	{
		path: "/userIndex",
		name: "userIndex",
		component: () =>
			import('@/page/userCenter/userCenterMain'),
		children: [{
			path: "/userIndex",
			name: "userIndex",
			component: () =>
				import(
					"@/page/userCenter/index"
				),
			meta: {
				title: '个人中心',
				breadcrumbList: [{
					name: '首页',
					path: '/'
				}]
			}
		}, ]
	},
	// 1.1我的银行卡
	{
		path: '/manage',
		name: '',
		component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/userCenterMain'),
		redirect: '/manage',
		children: [{
			path: "/manage",
			name: "manage",
			component: () =>
				import(
					"@/page/userCenter/manage/manage.vue"
				),
			meta: {
				title: '我的银行卡',
				breadcrumbList: [{
					name: '首页',
					path: '/'
				}, {
					name: '个人中心',
					path: '/userIndex'
				}]
			}
		}, ]
	},

	// 1.2交易记录
	{
		path: '/jiaoyi',
		name: '',
		component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/userCenterMain'),
		redirect: '/jiaoyi',
		children: [{
			path: "/jiaoyi",
			name: "jiaoyi",
			component: () =>
				import(
					"@/page/userCenter/jiaoyi/index.vue"
				),
			meta: {
				title: '交易记录',
				breadcrumbList: [{
					name: '首页',
					path: '/'
				}, {
					name: '个人中心',
					path: '/userIndex'
				}]
			}
		}, ]
	},
	// 1.3账户安全
	{
		path: '/finance',
		name: '',
		component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/userCenterMain'),
		redirect: '/finance',
		children: [{
			path: "/finance",
			name: "finance",
			component: () =>
				import(
					"@/page/userCenter/finance/finance.vue"
				),
			meta: {
				title: '账户安全',
				breadcrumbList: [{
					name: '首页',
					path: '/'
				}, {
					name: '个人中心',
					path: '/userIndex'
				}]
			}
		}, ]
	},
	// 1.4 操作记录
	{
		path: '/record',
		name: '',
		component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/userCenterMain'),
		redirect: '/record',
		children: [{
			path: "/record",
			name: "record",
			component: () =>
				import(
					"@/page/userCenter/record/index.vue"
				),
			meta: {
				title: '操作记录',
				breadcrumbList: [{
					name: '首页',
					path: '/'
				}, {
					name: '个人中心',
					path: '/userIndex'
				}]
			}
		}, ]
	},
	// 客户关系管理
	{
		path: '/customer',
		name: '',
		component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/userCenterMain'),
		redirect: '/customer',
		children: [{
			path: "/customer",
			name: "customer",
			component: () =>
				import(
					"@/page/userCenter/customer/index.vue"
				),
			meta: {
				title: '客户关系管理',
				breadcrumbList: [{
					name: '首页',
					path: '/'
				}, {
					name: '个人中心',
					path: '/userIndex'
				}]
			}
		}, ]
	},
	// 1.4 我的关注
	{
		path: '/guanzhu',
		name: '',
		component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/userCenterMain'),
		redirect: '/guanzhu',
		children: [{
			path: "/guanzhu",
			name: "guanzhu",
			component: () =>
				import(
					"@/page/userCenter/guanzhu/index.vue"
				),
			meta: {
				title: '我的收藏',
				breadcrumbList: [{
					name: '首页',
					path: '/'
				}, {
					name: '个人中心',
					path: '/userIndex'
				}]
			}
		}, ]
	},
	// 1.5 我的签章
	{
		path: '/qianzhang',
		name: '',
		component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/userCenterMain'),
		redirect: '/qianzhang',
		children: [{
			path: "/qianzhang",
			name: "qianzhang",
			component: () =>
				import(
					"@/page/userCenter/qianzhang/index.vue"
				),
			meta: {
				title: '我的签章',
				breadcrumbList: [{
					name: '首页',
					path: '/'
				}, {
					name: '个人中心',
					path: '/userIndex'
				}]
			}
		}, ]
	},
	{
		path: '/search',
		name: 'search',
		component: () => import('@/page/search/index.vue'),
	},
	//挂牌销售
	{
		path: '/markethangtag',
		name: '',
		component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/userCenterMain'),
		redirect: '/markethangtag',
		children: [{
			path: "/markethangtag",
			name: "markethangtag",
			component: () =>
				import("@/page/hangtag/markethangtag"),
			meta: {
				title: '挂牌销售',
				breadcrumbList: [{
					name: '首页',
					path: '/index'
				}, {
					name: '个人中心',
					path: '/userIndex'
				}]
			}
		}, ]
	},
	//挂牌采购
	// {
	//   path: "/purchasehangtag",
	//   name: "purchasehangtag",
	//   component: () =>
	//     import( "@/page/hangtag/purchase"),
	// },
	//挂牌销售详情
	// {
	// 	path: "/purcDetails",
	// 	name: "purcDetails",
	// 	component: () =>
	// 		import("@/page/hangtag/details/index"),
	// 	meta: {
	// 		title: '挂牌销售',
	// 		breadcrumbList: [{
	// 			name: '首页',
	// 			path: '/index'
	// 		}]
	// 	}
	// },
	//订单信息列表
	{
		path: '/order',
		name: '',
		component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/userCenterMain'),
		redirect: '/order',
		children: [{
			path: "/order",
			name: "order",
			component: () =>
				import("@/page/orderform/order"),
			meta: {
				title: '订单信息',
				breadcrumbList: [{
					name: '首页',
					path: '/'
				}, {
					name: '个人中心',
					path: '/userIndex'
				}]
			}

		}, ]
	},

	{
		path: '/LogisticsOrders',
		name: '',
		component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/userCenterMain'),
		redirect: '/LogisticsOrders',
		children: [{
			path: "/LogisticsOrders",
			name: "LogisticsOrders",
			component: () =>
				import("@/page/LogisticsOrders/index"),
			meta: {
				title: '订单信息',
				breadcrumbList: [{
					name: '首页',
					path: '/'
				}, {
					name: '个人中心',
					path: '/userIndex'
				}]
			}

		}, ]
	},





	//拼团信息
	{
		path: '/pieceInfo',
		name: '',
		component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/pieceInfo'),
		redirect: '/pieceInfo',
		children: [{
			path: "/pieceInfo",
			name: "pieceInfo",
			meta: {
				title: '订单信息',
				breadcrumbList: [{
					name: '首页',
					path: '/'
				}, {
					name: '个人中心',
					path: '/userIndex'
				}]
			}

		}, ]
	},
	// 拼团发布
	{
		path: '/groupPublishing',
		  name: '',
		  component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/userCenterMain'),
		    redirect: '/groupPublishing',
		    children: [{
		      path: "/groupPublishing",
		      name: "groupPublishing",
		      component: () =>
		        import("@/page/piecing/groupPublishing"),
		      meta: {
		        title: '发布拼团',
		        breadcrumbList: [{
		          name: '首页',
		          path: '/'
		        }, {
		          name: '个人中心',
		          path: '/userIndex'
		        }]
		      }

		    }, ]
		  },

		// 拼团参与
		{
			path: '/groupParticipation',
			name: '',
			component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/userCenterMain'),
			redirect: '/groupParticipation',
			children: [{
				path: "/groupParticipation",
				name: "groupParticipation",
				component: () =>
					import("@/page/piecing/groupParticipation"),
				meta: {
					title: '我参与的',
					breadcrumbList: [{
						name: '首页',
						path: '/'
					}, {
						name: '个人中心',
						path: '/userIndex'
					}]
				}
			}, ]
		},
	// 1.5 我的签章
	{
		path: "/edit-qianzhang",
		name: "edit-qianzhang",
		component: () =>
			import(
				"@/page/userCenter/qianzhang/edit-qianzhang.vue"
			),
		meta: {
			title: '我的签章',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}]
		}
	},
	{
		path: "/associateddetail",
		name: "associateddetail",
		component: () =>
			import(
				"@/page/servicesAll/myPublished/detail.vue"
			),
		meta: {
			title: '关联的服务详情',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}]
		}
	},
	// 1.5 我发布的服务
	{
		path: "/myPublished",
		name: "myPublished",
		component: () =>
			import(
				"@/page/servicesAll/myPublished/index.vue"
			),
		meta: {
			title: '我发布的服务',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}]
		}
	},
	{
		path: "/myPublisheddetail",
		name: "myPublisheddetail",
		component: () =>
			import(
				"@/page/servicesAll/myPublished/detail.vue"
			),
		meta: {
			title: '我发布的服务详情',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}, {
				name: '我发布的服务',
				path: '/myPublished'
			}]
		}
	},
	{
		path: "/logistics",
		name: "logistics",
		component: () =>
			import(
				"@/page/servicesAll/logistics/index.vue"
			),
		meta: {
			title: '物流服务订单',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}]
		}
	},
	{
		path: "/logisticsdetail",
		name: "logisticsdetail",
		component: () =>
			import(
				"@/page/servicesAll/logistics/detail.vue"
			),
		meta: {
			title: '物流服务订单详情',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}, {
				name: '物流服务订单',
				path: '/logistics'
			}]
		}
	},
	{
		path: "/financeTax",
		name: "financeTax",
		component: () =>
			import(
				"@/page/servicesAll/financeTax/index.vue"
			),
		meta: {
			title: '财税服务订单',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}]
		}
	},
	{
		path: "/financeTaxdatail",
		name: "financeTaxdatail",
		component: () =>
			import(
				"@/page/servicesAll/components/details.vue"
			),
		meta: {
			title: '财税服务订单详情',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}, {
				name: '财税服务订单',
				path: '/financeTax'
			}]
		}
	},
	{
		path: "/banking",
		name: "banking",
		component: () =>
			import(
				"@/page/servicesAll/banking/index.vue"
			),
		meta: {
			title: '金融服务订单',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}]
		}
	},
	{
		path: "/bankingdetail",
		name: "bankingdetail",
		component: () =>
			import(
				"@/page/servicesAll/banking/detail.vue"
			),
		meta: {
			title: '金融服务订单详情',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}, {
				name: '金融服务订单',
				path: '/banking'
			}]
		}
	},
	{
		path: "/warehouse",
		name: "warehouse",
		component: () =>
			import(
				"@/page/servicesAll/warehouse/index.vue"
			),
		meta: {
			title: '仓储服务订单',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}]
		}
	},
	{
		path: "/warehousedatail",
		name: "warehousedatail",
		component: () =>
			import(
				"@/page/servicesAll/components/details.vue"
			),
		meta: {
			title: '仓储服务订单详情',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}, {
				name: '仓储服务订单',
				path: '/warehouse'
			}]
		}
	},
	{
		path: "/science",
		name: "science",
		component: () =>
			import(
				"@/page/servicesAll/science/index.vue"
			),
		meta: {
			title: '科技服务订单',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}]
		}
	},
	{
		path: "/sciencedetail",
		name: "sciencedetail",
		component: () =>
			import(
				"@/page/servicesAll/components/details.vue"
			),
		meta: {
			title: '科技服务订单详情',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}, {
				name: '科技服务订单',
				path: '/science'
			}]
		}
	},
	// 我的服务
	{
		path: "/usmyPublished",
		name: "usmyPublished",
		component: () =>
			import(
				"@/page/usservicesAll/myPublished/index.vue"
			),
		meta: {
			title: '我关注的服务',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}]
		}
	},
	{
		path: "/appliedQuota",
		name: "appliedQuota",
		component: () =>
			import(
				"@/page/usservicesAll/myPublished/appliedQuota.vue"
			),
		meta: {
			title: '申请额度',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}, {
				name: '我关注的服务',
				path: '/usmyPublished'
			}]
		}
	},
	{
		path: "/uslogistics",
		name: "uslogistics",
		component: () =>
			import(
				"@/page/usservicesAll/logistics/index.vue"
			),
		meta: {
			title: '物流服务订单',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}]
		}
	},
	{
		path: "/uslogisticsdetail",
		name: "uslogisticsdetail",
		component: () =>
			import(
				"@/page/usservicesAll/logistics/detail.vue"
			),
		meta: {
			title: '物流服务订单详情',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}, {
				name: '物流服务订单',
				path: '/uslogistics'
			}]
		}
	},
	{
		path: "/usfinanceTax",
		name: "usfinanceTax",
		component: () =>
			import(
				"@/page/usservicesAll/financeTax/index.vue"
			),
		meta: {
			title: '财税服务订单',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}]
		}
	},
	{
		path: "/usfinanceTaxedatail",
		name: "usfinanceTaxdatail",
		component: () =>
			import(
				"@/page/usservicesAll/components/details.vue"
			),
		meta: {
			title: '财税服务订单',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}, {
				name: '财税服务订单',
				path: '/usfinanceTax'
			}]
		}
	},
	{
		path: "/usbanking",
		name: "usbanking",
		component: () =>
			import(
				"@/page/usservicesAll/banking/index.vue"
			),
		meta: {
			title: '金融服务订单',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}]
		}
	},
	{
		path: "/usbankingdetail",
		name: "ususbanking",
		component: () =>
			import(
				"@/page/usservicesAll/banking/detail.vue"
			),
		meta: {
			title: '金融服务订单详情',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}, {
				name: '金融服务订单',
				path: '/usbanking'
			}]
		}
	},
	{
		path: "/uswarehouse",
		name: "uswarehouse",
		component: () =>
			import(
				"@/page/usservicesAll/warehouse/index.vue"
			),
		meta: {
			title: '仓储服务订单',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}]
		}
	},
	{
		path: "/uswarehousedatail",
		name: "uswarehousedatail",
		component: () =>
			import(
				"@/page/usservicesAll/components/details.vue"
			),
		meta: {
			title: '仓储服务订单详情',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}, {
				name: '仓储服务订单',
				path: '/uswarehouse'
			}]
		}
	},
	{
		path: "/usscience",
		name: "usscience",
		component: () =>
			import(
				"@/page/usservicesAll/science/index.vue"
			),
		meta: {
			title: '科技服务订单',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}]
		}
	},
	{
		path: "/ussciencedatail",
		name: "ussciencedatail",
		component: () =>
			import(
				"@/page/usservicesAll/components/details.vue"
			),
		meta: {
			title: '科技服务订单详情',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}, {
				name: '科技服务订单',
				path: '/usscience'
			}]
		}
	},
	// 竞价销售 我发布的
	{
		path: '/released',
		name: '',
		component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/userCenterMain'),
		redirect: '/released',
		children: [{
			path: "/released",
			name: "released",
			component: () =>
				import("@/page/bidding/released"),
			meta: {
				title: '我发布的',
				breadcrumbList: [{
					name: '首页',
					path: '/'
				}, {
					name: '个人中心',
					path: '/userIndex'
				}]
			}
		}, ]
	},
	// 物流竞价我发布的
	{
		path: '/mylogistics',
		name: '',
		component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/userCenterMain'),
		redirect: '/mylogistics',
		children: [{
			path: "/mylogistics",
			name: "mylogistics",
			component: () =>
				import("@/page/mylogistics"),
			meta: {
				title: '我发布的',
				breadcrumbList: [{
					name: '首页',
					path: '/'
				}, {
					name: '个人中心',
					path: '/userIndex'
				}]
			}
		}, ]
	},
	// 物流竞价我参与的
	{
		path: '/participateIn',
		name: '',
		component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/userCenterMain'),
		redirect: '/participateIn',
		children: [{
			path: "/participateIn",
			name: "participateIn",
			component: () =>
				import("@/page/mylogistics/participateIn"),
			meta: {
				title: '我参与的',
				breadcrumbList: [{
					name: '首页',
					path: '/'
				}, {
					name: '个人中心',
					path: '/userIndex'
				}]
			}
		}, ]
	},


	// 竞价销售我参与的
	{
		path: '/participation',
		name: '',
		component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/userCenterMain'),
		redirect: '/participation',
		children: [{
			path: "/participation",
			name: "participation",
			component: () =>
				import("@/page/bidding/participation"),
			meta: {
				title: '我参与的',
				breadcrumbList: [{
					name: '首页',
					path: '/'
				}, {
					name: '个人中心',
					path: '/userIndex'
				}]
			}
		}, ]
	},
	// 竞价销售详情
	{
		path: "/details",
		component: () =>
			import("@/page/bidding/details/index"),
		meta: {
			title: '详情',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}]
		}
	},
	// 竞价采购我发布的
	{
		path: '/purchase',
		name: '',
		component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/userCenterMain'),
		redirect: '/purchase',
		children: [{
			path: "/purchase",
			name: "purchase",
			component: () =>
				import("@/page/purchase/purchase"),
			meta: {
				title: '我发布的',
				breadcrumbList: [{
					name: '首页',
					path: '/'
				}, {
					name: '个人中心',
					path: '/userIndex'
				}]
			}

		}, ]
	},
	// 竞价采购我发布的详情
	{
		path: "/logisticsDetailss",
		component: () =>
			import("@/page/mylogistics/logisticsDetails.vue"),
		meta: {
			title: '详情',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}]
		}
	},
	// 采购我参与的
	{
		path: '/take',
		name: '',
		component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/userCenterMain'),
		redirect: '/take',
		children: [{
			path: "/take",
			name: "take",
			component: () =>
				import("@/page/purchase/take"),
			meta: {
				title: '我参与的',
				breadcrumbList: [{
					name: '首页',
					path: '/'
				}, {
					name: '个人中心',
					path: '/userIndex'
				}]
			}
		}, ]
	},
	// 生产煤矿
	{
		path: '/Mining',
		name: '',
		component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/userCenterMain'),
		redirect: '/Mining',
		children: [{
			path: "/Mining",
			name: "Mining",
			component: () =>
				import("@/page/userCenter/Mining"),
			meta: {
				title: '生产煤矿',
				breadcrumbList: [{
					name: '首页',
					path: '/'
				}, {
					name: '个人中心',
					path: '/userIndex'
				}]
			}
		}, ]
	},

	// 基础信息
	{
		path: '/BsInformation',
		name: '',
		component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/userCenterMain'),
		redirect: '/BsInformation',
		children: [{
			path: "/BsInformation",
			name: "BsInformation",
			component: () =>
				import("@/page/userCenter/BsInformation"),
			meta: {
				title: '基础信息',
				breadcrumbList: [{
					name: '首页',
					path: '/'
				}, {
					name: '个人中心',
					path: '/userIndex'
				}]
			}
		}, ]
	},



	// 采购详情
	{
		path: "/purchaseDetails",
		name: "purchaseDetails",
		component: () =>
			import(

				"@/page/purchase/details/purchaseDetails"
			),
		meta: {
			title: '详情',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}]
		}
	},

	//挂牌销售
	{
		path: "/markethangtag",
		name: "markethangtag",
		component: () =>
			import("@/page/hangtag/markethangtag"),
		meta: {
			title: '挂牌销售',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}]
		}
	},
	//挂牌采购
	{
		path: '/purchasehangtag',
		name: '',
		component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/userCenterMain'),
		redirect: '/purchasehangtag',
		children: [{
			path: "/purchasehangtag",
			name: "purchasehangtag",
			component: () =>
				import("@/page/hangtag/purchase"),
			meta: {
				title: '挂牌采购',
				breadcrumbList: [{
					name: '首页',
					path: '/'
				}, {
					name: '个人中心',
					path: '/userIndex'
				}]
			}
		}, ]
	},
	//挂牌销售详情
	{
		path: "/purcDetails",
		name: "purcDetails",
		component: () =>
			import("@/page/hangtag/details/index"),
		meta: {
			title: '详情',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}, {
				name: '挂牌',
				path: '/purchasehangtag'
			}]
		}

	},
	//订单信息列表
	{
		path: "/order",
		name: "order",
		component: () =>
			import("@/page/orderform/order"),
		meta: {
			title: '订单信息',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}]
		}
	},
	//销售订单信息详情
	{
		path: "/orderDetails",
		name: "orderDetails",
		component: () =>
			import("@/page/orderform/orderDetails"),
		meta: {
			title: '订单详情',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}, {
				name: '订单信息',
				path: '/order'
			}]
		}
	},
	//采购订单信息详情
	{
		path: "/purchaseOrder",
		name: "purchaseOrder",
		component: () =>
			import("@/page/orderform/purchaseOrder"),
		meta: {
			title: '订单详情',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}, {
				name: '订单信息',
				path: '/order'
			}]
		}
	},




	//物流竞价订单详情
	{
		path: "/purchaseOn",
		name: "purchaseOn",
		component: () =>
			import("@/page/LogisticsOrders/purchase"),
		meta: {
			title: '订单详情',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}, {
				name: '订单信息',
				path: '/order'
			}]
		}
	},
	// 疆煤宝服务平台
	{
		path: "/coal/service/platform",
		name: "coalServicePlatform",
		component: () => import("@/page/coalServicePlatform"),
		meta: {
		  title: "疆煤宝服务平台",
		},
	  },
	//邀约交易
	{
		path: "/Invitation",
		name: "Invitation",
		component: () =>
			import("@/page/Invitation"),
		meta: {
			title: '邀约交易',
		}
	},

	{
		path: "/addInvitation",
		name: "addInvitation",
		component: () =>
			import("@/page/Invitation/addInvitation"),
		meta: {
			title: '发起邀约交易',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '邀约交易',
				path: '/Invitation'
			}]
		}
	},


	{
		path: "/InvitationDetailed",
		name: "InvitationDetailed",
		component: () =>
			import("@/page/Invitation/components/detailed"),
		meta: {
			title: '邀约交易详情',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '邀约交易',
				path: '/Invitation'
			}]
		}
	},
	//已完成订单信息详情
	{
		path: "/finishedDetails",
		name: "finishedDetails",
		component: () =>
			import("@/page/orderform/finishedDetails"),
		meta: {
			title: '已完成订单信息详情',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}]
		}
	},
	//合同
	{
		path: '/contract',
		name: '',
		component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/userCenterMain'),
		redirect: '/contract',
		children: [{
			path: "/contract",
			name: "contract",
			component: () =>
				import("@/page/contract/index"),
			meta: {
				title: '合同',
				breadcrumbList: [{
					name: '首页',
					path: '/'
				}, {
					name: '个人中心',
					path: '/userIndex'
				}]
			}
		}, ]
	},
	// 中长期合同
	{
		path: '/Medium',
		name: '',
		component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/Medium'),
		redirect: '/Medium',
		children: [{
			path: "/Medium",
			name: "Medium",
			component: () =>
				import("@/page/contract/index"),
			meta: {
				title: '中长期合同管理',
				breadcrumbList: [{
					name: '首页',
					path: '/'
				}, {
					name: '个人中心',
					path: '/userIndex'
				}]
			}
		}, ]
	},




	// 产运需运力合同模板
	{
		path: '/Template',
		name: '',
		component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/medium/Template'),
		redirect: '/Template',
		children: [{
			path: "/Template",
			name: "Template",
			component: () =>
				import("@/page/contract/index"),
			meta: {
				title: '中长期合同管理',
				breadcrumbList: [{
					name: '首页',
					path: '/'
				}, {
					name: '个人中心',
					path: '/userIndex'
				}]
			}
		}, ]
	},
	// 绑定销售/燃料公司
	{
		path: '/Dinding',
		name: '',
		component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/medium/Dinding'),
		redirect: '/Dinding',
		children: [{
			path: "/Dinding",
			name: "Dinding",
			component: () =>
				import("@/page/contract/index"),
			meta: {
				title: '中长期合同管理',
				breadcrumbList: [{
					name: '首页',
					path: '/'
				}, {
					name: '个人中心',
					path: '/userIndex'
				}]
			}
		}, ]
	},
	// 企业类型认证
	{
		path: '/certification',
		name: '',
		component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/medium/certification'),
		redirect: '/certification',
		children: [{
			path: "/certification",
			name: "certification",
			component: () =>
				import("@/page/contract/index"),
			meta: {
				title: '中长期合同管理',
				breadcrumbList: [{
					name: '首页',
					path: '/'
				}, {
					name: '个人中心',
					path: '/userIndex'
				}]
			}
		}, ]
	},
	{
		path: '/AddTemplate',
		name: '',
		component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/medium/AddTemplate/index'),
		redirect: '/AddTemplate',
		children: [{
			path: "/AddTemplate",
			name: "AddTemplate",
			component: () =>
				import("@/page/contract/index"),
			meta: {
				title: '模板维护',
				breadcrumbList: [{
					name: '首页',
					path: '/'
				}, {
					name: '个人中心',
					path: '/userIndex'
				}, {
					name: '中长期合同录入',
					path: '/Template'
				}]
			}
		}, ]
	},
	//合同录入
	{
		path: '/Enter',
		name: '',
		component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/medium/Enter'),
		redirect: '/Enter',
		children: [{
			path: "/Enter",
			name: "Enter",
			component: () =>
				import("@/page/contract/index"),
			meta: {
				title: '中长期合同管理',
				breadcrumbList: [{
					name: '首页',
					path: '/'
				}, {
					name: '个人中心',
					path: '/userIndex'
				}]
			}
		}, ]
	},
	//新增合同录入
	{
		path: '/AddEnter',
		name: '',
		component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/medium/AddEnter/index'),
		redirect: '/AddEnter',
		children: [{
			path: "/AddEnter",
			name: "AddEnter",
			component: () =>
				import("@/page/contract/index"),
			meta: {
				title: '中长期合同录入',
				// keepAlive: true,
				// isBack: false,
				breadcrumbList: [{
					name: '首页',
					path: '/'
				}, {
					name: '个人中心',
					path: '/userIndex'
				}, {
					name: '合同录入',
					path: '/Enter'
				}]
			}
		}, ]
	},
	// 合同确认
	{
		path: '/confirm',
		name: '',
		component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/medium/confirm'),
		redirect: '/confirm',
		children: [{
			path: "/confirm",
			name: "confirm",
			component: () =>
				import("@/page/contract/index"),
			meta: {
				title: '中长期合同管理',
				breadcrumbList: [{
					name: '首页',
					path: '/'
				}, {
					name: '个人中心',
					path: '/userIndex'
				}]
			}
		}, ]
	},
	//合同作废
	{
		path: '/Tovoid',
		name: '',
		component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/medium/Tovoid'),
		redirect: '/Tovoid',
		children: [{
			path: "/Tovoid",
			name: "Tovoid",
			component: () =>
				import("@/page/contract/index"),
			meta: {
				title: '中长期合同管理',
				breadcrumbList: [{
					name: '首页',
					path: '/'
				}, {
					name: '个人中心',
					path: '/userIndex'
				}]
			}
		}, ]
	},
	//合同查询
	{
		path: '/Query',
		name: '',
		component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/medium/Query'),
		redirect: '/Query',
		children: [{
			path: "/Query",
			name: "Query",
			component: () =>
				import("@/page/contract/index"),
			meta: {
				title: '中长期合同管理',
				breadcrumbList: [{
					name: '首页',
					path: '/'
				}, {
					name: '个人中心',
					path: '/userIndex'
				}]
			}
		}, ]
	},
	//月度履约数据推送
	{
		path: '/Performance',
		name: '',
		component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/medium/Performance'),
		redirect: '/Performance',
		children: [{
			path: "/Performance",
			name: "Performance",
			component: () =>
				import("@/page/contract/index"),
			meta: {
				title: '中长期合同管理',
				breadcrumbList: [{
					name: '首页',
					path: '/'
				}, {
					name: '个人中心',
					path: '/userIndex'
				}]
			}
		}, ]
	},
	//推送
	{
		path: '/Submitted',
		name: '',
		component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/medium/Submitted/index'),
		redirect: '/Submitted',
		children: [{
			path: "/Submitted",
			name: "Submitted",
			component: () =>
				import("@/page/contract/index"),
			meta: {
				title: '中长期合同管理',
				breadcrumbList: [{
					name: '首页',
					path: '/'
				}, {
					name: '个人中心',
					path: '/userIndex'
				}]
			}
		}, ]
	},


	//关于我们
	{
		path: "/aboutus",
		name: "aboutus",
		component: () =>
			import("@/page/aboutus/index"),
		meta: {
			// title: '关于我们',
			// breadcrumbList: [{
			// 	name: '首页',
			// 	path: '/'
			// }]
		}
	},

	// 中长期资料
	{
		path: "/MediumData",
		name: "MediumData",
		component: () =>
			import("@/page/MediumData/index"),
		meta: {
			// title: '中长期合同相关资料下载',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}]
		}
	},
	// 煤交保供
	{
		path: "/exchange",
		name: "exchange",
		component: () =>
			import("@/page/exchange/index"),
		// meta: {
		// 	title: '煤交保供',
		// 	breadcrumbList: [{
		// 		name: '首页',
		// 		path: '/'
		// 	}]
		// }
	},


	{
		path: "/medidetale",
		name: "medidetale",
		component: () =>
			import("@/page/MediumData/medidetale"),
		meta: {
			// title: '中长期合同相关资料下载',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}]
		}
	},
	//消息
	{
		path: "/information",
		name: "information",
		component: () =>
			import("@/page/information/index"),
		meta: {
			title: '消息',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}]
		}
	},
	//金融(暂未开放)
	// {
	//   path: "/financial",
	//   name: "financial",
	//   component: () =>
	//     import( "@/page/financial/index"),
	// },

	//用户协议
	{
		path: '/protocol',
		name: 'protocol',
		component: () => import('@/page/login/protocol'),
		meta: {
			title: '用户协议',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}]
		}
	},

	//金融(暂未开放)  现改完服务大厅
	{
		path: '/financial',
		name: 'financial',
		component: () =>
			import('@/page/financial/index'),
		meta: {
			title: '服务大厅',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}]
		}
	},
	{
		path: '/railway',
		name: 'railway',
		component: () =>
			import('@/page/financial/railway/index'),

	},

	//成为服务商
	{
		path: '/addfacilitator',
		name: 'addfacilitator',
		component: () => import('@/page/financial/addfacilitator/index'),
		meta: {
			title: '成为服务商',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '服务大厅',
				path: '/financial'
			}]
		}
	},
	//商品详情
	{
		path: '/financialDetails',
		name: 'financialDetails',
		component: () => import('@/page/financial/details/index'),
		meta: {
			title: '详情',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '服务大厅',
				path: '/financial'
			}]
		}
	},
	//商品详情
	{
		path: '/financialDetailsf',
		name: 'financialDetailsf',
		component: () => import('@/page/financial/details/index'),
		meta: {
			title: '我发布的服务详情',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '个人中心',
				path: '/userIndex'
			}, {
				name: '我关注的服务',
				path: '/usmyPublished'
			}]
		}
	},
	//商品详情
	{
		path: '/immediately',
		name: 'immediately',
		component: () => import('@/page/financial/immediately/index'),
		meta: {
			title: '立即购买',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '服务大厅',
				path: '/financial'
			}, {
				name: '详情',
				path: '/financialDetails'
			}]
		}
	},
	//发布服务
	{
		path: '/issueServe',
		name: 'issueServe',
		component: () => import('@/page/financial/issueServe/index'),
		meta: {
			title: '发布服务',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}, {
				name: '服务大厅',
				path: '/financial'
			}]
		}
	},
// 煤交保供
{
	path: "/exchange",
	name: "exchange",
	component: () =>
		import("@/page/exchange/index"),
	// meta: {
	// 	title: '煤交保供',
	// 	breadcrumbList: [{
	// 		name: '首页',
	// 		path: '/'
	// 	}]
	// }
},
	//搜索结果
	{
		path: '/search',
		name: 'search',
		component: () => import('@/page/search/index'),
		meta: {
			title: '搜索内容',
			breadcrumbList: [{
				name: '首页',
				path: '/'
			}]
		}

	},

	{
		path: '/freighth5',
		name: 'freighth5',
		component: () => import('@/page/freighth5/index')
	},
	{
		path: '/freighth5details',
		name: 'freighth5details',
		component: () => import('@/page/freighth5/detail/index')
	},


	{
		path: "*",
		component: () => import("@/page/404"),
	},
];