export default {
  getOnlineContractInfo:'/trade/trade/onlineContract/getOnlineContractInfo',
  getOnlineContractTransportInfo:'/trade/trade/onlineContract/getOnlineContractTransportInfo',
  updateOnlineContractInfo:'/trade/trade/onlineContract/updateOnlineContractInfo',
  updateOnlineContractTransportInfo:'/trade/trade/onlineContract/updateOnlineContractTransportInfo',
  getAboutList: '/system/sysDictData/getSysDictDataByByDictType', // 获取公司介绍
  getMessage: '/system/sysMessage/page', //获取消息列表
  updateAll: '/system/sysMessage/readStatus/updateAll', //标记所有消息为已读
  tradeGoods: '/trade/tradeGoods/page', // 竞价销售-我发布的，列表
  tradeDtetails: `/trade/tradeGoods/`, // 竞价销售-我发布的，详情
	// 邀约商品详情
	getInvoiceDetail:'/trade/tradeInvite/detailTradeInvite',
  setclose: `/trade/tradeGoods/close/`, // 取消发布
  tradeOffer: '/trade/tradeOffer/page', // 竞价公司列表
  tradeOrder: '/trade/tradeOrder/page', //订单信息列表
  orderDetail: '/trade/tradeOrder/detail', //列表详情
  ossUploadFile: "/third/third/ossUploadFile", //文件上传
  ossUploadFiletwo: "/third/third/ossUploadFile2", //文件上传2
  saveContractUrl: "/trade/tradeOrder/contract/saveContractUrl", //合同保存地址
  invoicepage: "/trade/tradeOrder/invoice/page", //合同保存地址
  paymentpage: "/trade/tradeOrder/payment/page", //支付记录
  trafficpage: "/trade/tradeOrder/traffic/page", //运输记录
  signpage: "/trade/tradeOrder/sign/page", //运输记录
  addtrafficpage: '/trade/tradeOrder/traffic/add', //发起运输
  addinvoice: "/trade/tradeOrder/invoice/add", //发起开票
  detailByOrder: `/trade/tradeOrder/contract/detail/`, //获取合同
  confirm: "/trade/tradeOrder/contract/confirm", //确认合同无误
  addpayment: "/trade/tradeOrder/payment/add", //线下支付
  addconfirm: "/trade/tradeOrder/payment/confirm", //确认金额已到账
  addsign: '/trade/tradeOrder/sign/add', //订单签收信息增加
  finish: "/trade/tradeOrder/finish", //签收满意程度
  listTemplate: "/trade/tradeOrder/contract/listTemplate", //获取合同模板
  updateVoucher: "/trade/tradeOrder/payment/updateVoucher", //订单支付信息补充付款凭证
  tradeGoodsClose: '/trade/tradeGoods/close/', //关闭商品
  listByDictType: '/system/sysDictData/listByDictType', //查询服务
  serviceGoodpage: '/trade/serviceGood/page', //服务大厅列表
  serviceProvider: '/system/serviceProvider/apply', //成为服务商
  serviceGood: '/trade/serviceGood/apply', //添加服务
  confirmInvoice: '/trade/tradeOrder/invoice/confirmInvoice', //开票确认
  getServiceProviderStatus: '/system/serviceProvider/getServiceProviderStatus', //获取服务商当前状态
  serviceGooddetail: '/trade/serviceGood/detail?serviceGoodId=', //获取详情
  collection: '/trade/serviceGoodCollection/collection', //收藏、取消收藏
  placeOtherOrder: '/trade/serviceOrder/placeOtherOrder', //其他订单下单
  serviceGoodCollection: "/trade/serviceGoodCollection/page", //我收藏的服务商品分页查询
  setedit: "/trade/tradeOrder/traffic/edit", //修改订单信息
  placeTransportOrderFromTrade: '/trade/serviceOrder/placeTransportOrderFromTrade', //选择服务
  listTransportCarsByParam: '/trade/serviceOrder/listTransportCarsByParam', //查看运输车辆
  ossUploadFiletw: "/third/third/ossUploadFile2", //文件上传2
  confirmDeliver: "/trade/tradeOrder/sign/confirmDeliver", //确认到货
  confirmReceive: "/trade/tradeOrder/sign/confirmReceive", //  确认收货
  confirmSettle: "/trade/tradeOrder/sign/confirmSettle",//买方确认收货
  getNewestInvoice:"/trade/tradeOrder/invoice/getNewestInvoice",//获取最新开票信息
  payServerOrder:"/trade/serviceOrder/payServerOrder",//服务订单支付
  financeBankCardadd:'/finance/financeBankCard/add',//添加用户银行卡
  bankCardList:'/system/sysUser/bankCardList',//查看银行卡
  withdraw:'/finance/financeWithdraw/withdraw',//提现申请
  getNowBalances:'/finance/financeWallet/getNowBalances',//获取当前用户余额
  financeCapitalAccount:'/finance/financeCapitalAccount/list?enterpriseId=',//查询用户资金账户列表
  financeBankCard:'/finance/financeBankCard/unBind',//解绑银行卡
  payTradeOrder:'/trade/tradeGoods/payTradeOrder',//交易订单支付
  updateSignatureStatus:'/tradeOrder/contract/updateSignatureStatus',
  deleteAll:'/system/sysMessage/readStatus/deleteAll',//清理已读
  groupDtetails:'/trade/tradeGroup/getGroupDetail?goodsId=',//拼团交易详情
  listAdjustPrice:'/trade/tradeOrder/listAdjustPrice',//订单历史价格
  addAdjustPrice:'/trade/tradeOrder/addAdjustPrice',//添加调价信息
  getOrderPrice:'/trade/tradeOrder/getOrderPrice',//订单价格查看
  dealAdjustPrice:'/trade/tradeOrder/dealAdjustPrice',//同意/拒绝调价信息
  getSignDetail:'/trade/tradeOrder/sign/detail',//订单结算详情
  exportSign:'/trade/tradeOrder/sign/export',//导出结算数据
  returnPrepayment:'/trade/tradeOrder/sign/returnPrepayment',//退还预付款
  cancelSettle:'/trade/tradeOrder/sign/cancelSettle',//取消结算
}
