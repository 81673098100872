import {
    gmvdetail
} from "@/api/home";
export default {
    state: {
        gmv: null
    },
    mutations: {
        setGmv(state, payload) {
            state.gmv = payload
        }
    },
    actions: {
        async getGmvdetail({ commit, state }, data) {
            if (!state.gmv) {
                const gmv = await gmvdetail(data);
                if (gmv.code == 0) {
                    commit('setGmv', gmv.data)
                }
            }
        }
    },
}