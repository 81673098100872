import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import rootModule from './rootModule'
import getters from './getters'
Vue.use(Vuex)
const storage = window.localStorage
const expireTime = 1000 * 20 * 60  // 20分钟失效重新请求
const files = require.context('./modules', false, /\.js$/);
rootModule.modules = files.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = files(modulePath)
  modules[moduleName] = value.default
  modules[moduleName].namespaced = true
  return modules
}, {})
rootModule.getters = getters
export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: {
        getItem (key) {
          let val = storage.getItem(key)
          if (!val || val == 'null') return val
          val = JSON.parse(val)
          if (Date.now() > val.time + val.expire) {
            console.log('值已失效')
            storage.removeItem(key)
            return '值已失效'
          }
          return val.data
        },
        setItem (key, value) {
          let obj = {
            data: JSON.parse(value),
            time: Date.now(),
            expire: expireTime
          }
          storage.setItem(key, JSON.stringify(obj))
        },
        removeItem (key) {
          storage.removeItem(key)
        },
      },
      reducer (val) {
        return {
          index: val.index
        };
      },
    }),
  ],
  ...rootModule
})