<template>
  <div class="header">
    <div class="top">
      <div class="login">
        <div class="to_index" style="display: flex">
          <div
            style="color: #000000; font-size: 16px"
            @click="$router.push('/index')"
            v-if="istrue"
          >
            新疆煤炭交易中心欢迎您
          </div>
          <div style="color: #000000" @click="$router.push('/index')" v-else>
            新疆煤炭交易中心北疆分中心欢迎您
          </div>
          <!-- <div @click="$router.push('/index')" v-else>
            新疆煤炭交易中心北疆分中心欢迎您
          </div>
          <div v-if="istrue">
            <span @click="goTOon('https://north.xjcec.com/#/index')" style="text-decoration: none">【可前往
              <span class="title_top" style="margin-left: 5px">北疆分中心</span>
            </span>
            <span class="space" style="margin: 0 5px">|</span>
            <span @click="goTOon(2)" style="text-decoration: none">
              <span class="title_top" style="margin-left: 5px">南疆分中心</span>
              】</span>
          </div>
          <div v-else>
            <span @click="goTOon('https://www.xjcec.com/#/index')" style="text-decoration: none">【可前往
              <span class="title_top" style="margin-left: 5px">新疆煤炭交易中心</span>
            </span>
            <span class="space" style="margin: 0 5px">|</span>
            <span @click="goTOon(2)" style="text-decoration: none">
              <span class="title_top" style="margin-left: 5px">南疆分中心</span>
              】</span>
          </div> -->
        </div>
        <!-- 首页 -->
        <div class="login_info">
          <div class="fuwu" style="display: flex; align-items: center">
            <img
              src="../assets/zxx_img/icon.png"
              alt=""
              style="margin-right: 8px; width: 14px; height: 14px"
            />
            服务热线：0902-2305929
          </div>
          <div style="color: #989898" v-if="!isLogin && path !== '/login'">
            |
          </div>
          <div
            class="botton"
            style="width: 200px"
            v-if="!isLogin && path !== '/login'"
          >
            <span @click="tologin(2)">免费注册</span>
            <span style="margin: 0 15px"> | </span>
            <span style="color: #3778ff" @click="tologin(1)">用户登录</span>
          </div>
          <div style="color: #989898" v-if="!isLogin && path !== '/login'">
            |
          </div>
          <div v-if="isLogin && path !== '/login'" class="uesrname">
            <el-dropdown class="user-name" @command="handleCommand">
              <span class="el-dropdown-link">
                {{ username }} <i class="el-icon-caret-bottom"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="loginout">退出登录</el-dropdown-item>
                <el-dropdown-item command="modifypsw"
                  >修改密码</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div
            class="botton login_botton"
            @click="personageClick('userIndex')"
            v-show="path !== '/login'"
          >
            个人中心
          </div>
          <div style="color: #989898" v-show="path !== '/login'">|</div>
          <div
            class="botton login_botton"
            style="width: 104px"
            @click="personageClick('information')"
            v-show="path !== '/login'"
          >
            我的消息
            <el-badge
              v-show="notread !== 0"
              :max="99"
              class="mark"
              :value="notread"
            />
          </div>
          <div style="color: #989898" v-show="path !== '/login'">|</div>
          <img class="navIcon" src="../assets/images/navIcon.png" />
          <div
            style="color: #ff851d; margin-left: 10px; cursor: pointer"
            @click="toDevelopment"
          >
            能源数据管理中心
          </div>
        </div>
      </div>
    </div>
    <div class="search_box header_center">
      <div class="home_menu_logo" v-if="istrue"></div>
      <div class="home_menu_logos" v-else></div>
      <div class="serrch_box_inner">
        <div>
          <el-input
            placeholder="请输入商品名称"
            v-model="product"
            maxlength="30"
            class="input-with-select"
          >
            <el-select v-model="select" slot="prepend" placeholder="请选择">
              <el-option label="挂牌销售" :value="0"></el-option>
              <el-option label="挂牌采购" :value="1"></el-option>
              <el-option label="竞价销售" :value="2"></el-option>
              <el-option label="竞价采购" :value="3"></el-option>
              <!-- <el-option label="拼团交易" :value="4"></el-option> -->
            </el-select>
            <el-button
              slot="append"
              style="background: #4861fe"
              @click="searchClick"
              >搜索</el-button
            >
          </el-input>
        </div>
      </div>
      <div style="display: flex" v-if="str1 != 'https://north.xjcec.com/'">
        <div class="Bjiang" @click="goTOon('https://north.xjcec.com/#/index')">
          北疆分中心
        </div>
        <div class="Njiang" style="margin-left: 13px" @click="goTOon(2)">
          南疆分中心
        </div>
      </div>
      <div style="display: flex" v-else>
        <div class="coal" @click="coal">返回首页</div>
        <div class="Njiang" style="margin-left: 13px" @click="goTOon(2)">
          南疆分中心
        </div>
      </div>
      <!-- <div v-if="path !== '/login'" class="erweima-box">
        <div class="erweima">
          <div class="erweima_img"></div>
          <div class="erweima_title">官方公众号</div>
        </div>
        <div class="erweima">
          <div class="erweima_img"></div>
          <div class="erweima_title">官方小程序</div>
        </div>
      </div> -->
      <!-- <p v-if="path == '/login'" class="index" @click="$router.push('/index')">
        <span class="hy-icon-fanhui"></span>
        首页
      </p> -->
    </div>
    <Nav></Nav>

    <el-dialog
      title="修改密码"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
      :modal-append-to-body="false"
    >
      <div>
        <el-form
          :model="numberValidateForm"
          ref="numberValidateForm"
          :rules="rules"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="原密码" prop="oldPassword">
            <el-input
              v-model="numberValidateForm.oldPassword"
              autocomplete="off"
              placeholder="请输入原密码"
              maxlength="12"
              minlength="6"
              :type="OlinputType"
            ></el-input>
            <div class="year" @click="yearClick">
              <i class="el-icon-view"></i>
            </div>
          </el-form-item>
          <el-form-item label="新密码" prop="newPassword">
            <el-input
              v-model="numberValidateForm.newPassword"
              autocomplete="off"
              placeholder="请输入新密码"
              maxlength="12"
              minlength="6"
              :type="newPasswordType"
            ></el-input>
            <div class="year" @click="newyearClick">
              <i class="el-icon-view"></i>
            </div>
          </el-form-item>
          <el-form-item label="新密码" prop="confirmPassword">
            <el-input
              v-model="numberValidateForm.confirmPassword"
              placeholder="请确认新密码"
              maxlength="12"
              :type="confirmType"
            ></el-input>
            <div class="year" @click="confirmyearClick">
              <i class="el-icon-view"></i>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('numberValidateForm')"
              >提交</el-button
            >
            <el-button @click="resetForm('numberValidateForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <el-dialog
      title=""
      :visible.sync="indialog"
      width="45%"
      append-to-body
      center
    >
      <div class="diaimg">
        <img src="../assets/mei/8.png" alt="" />
        <div class="linjian">系统正在建设中敬请期待......</div>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button  utton @click="indialog = false">取 消</el-button>
        <el-button type="primary" @click="indialog = false"
          >返 回</el-button
        >
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
import { logIn, biddingList, getqxshuju, userUpdatePassword } from "@/api/home";
import * as types from "@/store/action-types";
import Vue from "vue";
import md5 from "js-md5";
import { mapActions, mapMutations } from "vuex";
const { baseUrl, baseUrlByDm } = require("@/config");
export default {
  data() {
    return {
      str1: "",
      indialog: false,
      OlinputType: "password",
      newPasswordType: "password",
      confirmType: "password",
      centerDialogVisible: false,
      numberValidateForm: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      rules: {
        oldPassword: [
          { required: true, message: "请输入原密码", trigger: "blur" },
        ],
        newPassword: [
          {
            required: true,
            message: "请输入新密码",
            trigger: "blur",
          },
          {
            pattern: /^[^\u4e00-\u9fa5]+$/,
            message: "密码只限字母和数字",
            trigger: "blur",
          },
          {
            pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/,
            message: "请勿输入空格等特殊符号",
            trigger: "blur",
          },
          {
            min: 6,
            max: 12,
            message: "密码为6-12位字母和数字",
            trigger: "blur",
          },
        ],
        confirmPassword: [
          {
            required: true,
            message: "请确认新密码",
            trigger: "blur",
          },
          {
            pattern: /^[^\u4e00-\u9fa5]+$/,
            message: "密码只限字母和数字",
            trigger: "blur",
          },
          {
            pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/,
            message: "请勿输入空格等特殊符号",
            trigger: "blur",
          },
          {
            min: 6,
            max: 12,
            message: "密码为6-12位字母和数字",
            trigger: "blur",
          },
        ],
      },
      timer: "",
      path: "",
      isLogin: true,
      search: "",
      username: "",
      product: "",
      select: 0,
      notread: 0,
      baseUrl: "",
      baseUrlByDm: "",
      istrue: true,
    };
  },
  watch: {
    $route(to, from) {
      this.path = to.path;
      this.isLogins();
    },
  },
  components: {
    Nav: () => import("@/components/nav1"),
  },
  activated() {
    this.select = Vue.ls.get(types.SELEST);
  },
  beforeDestroy() {
    //页面关闭前关闭定时器 （这个才有用）
    clearInterval(this.timer);
    this.timer = null;
  },
  mounted() {
    this.select = Vue.ls.get(types.SELEST);
    this.isLogins();
    this.getmessage();
    this.everything();
    let a = document.URL;
    var str1 = a.substring(0, a.indexOf("#"));
    this.str1 = str1;
    if (str1 == "https://north.xjcec.com/") {
      this.istrue = false;
    } else {
      this.istrue = true;
    }
    this.path = this.$route.path;
    // this.searchList();
  },
  methods: {
    ...mapActions({
      searchList: "search/searchList",
    }),
    ...mapMutations({
      changesearch: "search/permission",
      selectchange: "search/selectchange",
    }),
    async everything() {
      const userId = Vue.ls.get(types.userId);
      if (userId) {
        await getqxshuju().then((res) => {
          if (res.code == 0) {
            if (res.data) {
              this.username = res.data.companyName;
              window.localStorage.setItem(
                "userName",
                res.data.companyName || ""
              );
            }
          }
        });
      }
    },
    yearClick() {
      this.OlinputType == "password"
        ? (this.OlinputType = "text")
        : (this.OlinputType = "password");
    },
    newyearClick() {
      this.newPasswordType == "password"
        ? (this.newPasswordType = "text")
        : (this.newPasswordType = "password");
    },
    confirmyearClick() {
      this.confirmType == "password"
        ? (this.confirmType = "text")
        : (this.confirmType = "password");
    },
    getmessage() {
      let id = Vue.ls.get(types.enterpriseId);
      if (id) {
        biddingList({
          enterpriseId: id,
          messageType: 0,
          readStatus: 0,
        }).then((res) => {
          if (res.code == 0) {
            this.notread = res.data.total;
          }
        });
      }
      this.timer = setInterval((i) => {
        biddingList({
          enterpriseId: id,
          messageType: 0,
          readStatus: 0,
        }).then((res) => {
          if (res.code == 0) {
            this.notread = res.data.total;
          }
        });
      }, 180000);
    },
    handleCommand(command) {
      if (command === "loginout") {
        this.layout();
      }
      if (command === "modifypsw") {
        this.centerDialogVisible = true;
        this.$refs["numberValidateForm"].resetFields();
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // console.log(md5())
          let config = {
            oldPassword: md5(this.numberValidateForm.oldPassword),
            newPassword: md5(this.numberValidateForm.newPassword),
            confirmPassword: md5(this.numberValidateForm.confirmPassword),
            userId: Vue.ls.get(types.userId),
          };
          userUpdatePassword(config).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: "修改成功，请重新登录",
                type: "success",
              });
              setTimeout(() => {
                this.layout();
              }, 3000);
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    personageClick(res) {
      // if (!this.beforhandle(res)) return;
      if (res == "information") {
        if (!this.beforhandle("myNews")) return;
      } else if (res == "userIndex") {
        if (!this.beforhandle("personalCenter")) return;
      }
      if (res == "userIndex") {
        this.$router.push("/userIndex");
      } else {
        if (!this.beforhandle("myNews")) return;
        this.$router.push("/information");
      }
    },
    over() {
      document.getElementById("layout").style.display = "block";
      // console.log(this.$route.path);
    },
    out() {
      document.getElementById("layout").style.display = "none";
    },
    layout() {
      window.localStorage.clear();
      this.isLogins();
      let config = {
        grant_type: "init",
        client_id: "xjcec",
        client_secret: "xjcec_secret",
        scope: "all",
      };
      if (this.$route.path != "/index") {
        this.$router.push("/index");
      } else {
        window.location.reload();
      }
      logIn(config).then((res) => {
        if (res.code == 0) {
          Vue.ls.set(types.ACCESS_TOKEN, res.data.accessToken);
          Vue.ls.set(types.REFRESH_TOKEN, res.data.refreshToken);
          Vue.ls.set(types.checkUkeyWhite, res.data.checkUkeyWhite);
          Vue.ls.set(types.EXPIRES_IN, res.data.expiresTime + "000");
        }
      });
    },
    goTOon(val) {
      if (val && val != 2) {
        const token = Vue.ls.get(types.ACCESS_TOKEN);
        const userId = Vue.ls.get(types.userId);
        if (
          token != undefined &&
          token != "" &&
          userId != undefined &&
          userId != "null" &&
          userId != ""
        ) {
          window.open(
            "https://north.xjcec.com/" +
              "#/login?token=" +
              token +
              "&userId=" +
              userId
          );
        } else {
          window.open("https://north.xjcec.com/");
        }
      }
      if (val == 2) {
        this.indialog = true;
        // this.$message({
        //   message: "正在建设中！",
        //   type: "warning",
        // });
      }
    },
    //返回煤炭首页
    coal() {
      window.open("https://www.xjcec.com/#/");
    },
    //搜索
    searchClick() {
      if (!this.beforhandle("all")) return;
      Vue.ls.set(types.SELEST, this.select);
      this.$router.push({
        path: "/search",
        query: {
          search: this.product,
          select: this.select,
        },
      });
      this.changesearch(this.product);
      this.selectchange(this.select);
      this.searchList();
    },
    isLogins() {
      this.username = window.localStorage.getItem("userName");
      let token = window.localStorage.getItem("token");
      this.isLogin = token ? true : false;
    },
    tologin(type) {
      this.$router.push({
        path: "/login",
        query: {
          key: type,
        },
      });
    },
    toDevelopment() {
      window.open(baseUrlByDm);
    },
    tozhuce() {
      this.$router.push("/protocol");
    },
    toOtherPage(data) {
      if (data.id) {
        this.$router.push({
          path: data.path,
          query: {
            id: data.id,
            title: data.title,
          },
        });
      } else {
        this.$router.push({
          path: data.path,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.diaimg {
  text-align: center;

  img {
    text-align: center;
    width: 500px;
    height: auto;
  }
}

.mark {
  top: -2px;
  left: -9px;
}

.login_botton {
  height: 33px;
  line-height: 33px;
}

.erweima {
  width: 70px;

  .erweima_img {
    width: 70px;
    height: 70px;
    background: url("../assets/img/qucode.jpg") no-repeat;
    background-size: 100% 100%;
  }

  .erweima_title {
    font-size: 10px;
    text-align: center;
  }
}

.index {
  margin-top: 36px;
  color: #4e6ef2;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
}

::v-deep .el-input-group__prepend {
  color: #000;
  font-size: 15px;
  font-weight: 400;
  width: 113px !important;
  background: #ffffff;
  border: 1px solid #00a0e9;
  border-right: 0;
  border-radius: 2px 0 0px 2px;
}

::v-deep .el-input-group__append {
  border: 1px solid #00a0e9;
  background: linear-gradient(-45deg, #00a0ff, #0a65ff);
  border-radius: 0px 2px 2px 0px;
  color: #fff;
}

::v-deep .el-select {
  color: #000;
}

::v-deep .el-select-dropdown {
  z-index: 999999 !important;
}

::v-deep .el-input-group--append {
  .el-input__inner {
    border: 1px solid #00a0e9;
    border-left: 0px;
    border-right: 0px;
  }
}

.serrch_box_inner {
  width: 460px;
  margin-right: 20px;
}

.header_center {
  width: 75%;
  margin: 0 auto;
}

.uesrname {
  position: relative;
}

.layout {
  display: none;
  position: absolute;
  bottom: -21px;
  right: 0;
  width: 100px;
  line-height: -45;
  font-size: 16px;
  color: #000;
  text-align: center;
}

.botton {
  color: #999999;
  font-size: 14px;
  width: 100px;
  text-align: center;
  cursor: pointer;
}

.uesrname {
  color: #999999;
  font-size: 16px;
  // width: 120px;
  text-align: center;
  cursor: pointer;
}

.fuwu {
  color: #ff7019;
  padding-right: 18px;
}

.span {
  color: #fff;
  font-size: 16px;
  height: 14px;
  width: 10px;
  text-align: center;
  line-height: 14px;
  cursor: pointer;
}
@media (max-width: 1000px) {
  .search_box {
    width: 1230px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 92px;
    background-color: #fff;
  }
  .header {
    width: 75%;
    background-color: #fff;
    .top {
      background: #f5f5f5;
      height: 48px;
    }
    .login {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 48px;
      line-height: 48px;
      width: 1200px;
      margin: 0 auto;

      .to_index {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        cursor: pointer;
        padding-left: 10px;
      }

      .login_info {
        display: flex;
        height: 33px;
        align-items: center;
      }
    }

    .home-header {
      height: 80px;
      background: #fff;
    }

    .home_menu_logo {
      /* margin-top: 15px; */
      /* margin-right: 171px; */
      width: 198px;
      height: 44px;
      background: url("../assets/img/logo03.png") no-repeat;
      background-size: 100% 100%;
    }

    .home_menu_logos {
      margin-top: 15px;
      margin-right: 171px;
      width: 340px;
      height: 76px;
      background: url("../assets/zxx_img/bei/logo.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}

@media (min-width: 1000px) {
  .search_box {
    width: 1440px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 92px;
    background-color: #fff;
  }
  .header {
    width: 100%;
    background-color: #fff;
    .top {
      background: #f5f5f5;
      height: 48px;
    }
    .login {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 48px;
      line-height: 48px;
      width: 1440px;
      margin: 0 auto;

      .to_index {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        cursor: pointer;
        padding-left: 10px;
      }

      .login_info {
        display: flex;
        height: 33px;
        align-items: center;
      }
    }

    .home-header {
      height: 80px;
      background: #fff;
    }

    .home_menu_logo {
      /* margin-top: 15px; */
      /* margin-right: 171px; */
      width: 198px;
      height: 44px;
      background: url("../assets/img/logo03.png") no-repeat;
      background-size: 100% 100%;
    }

    .home_menu_logos {
      margin-top: 15px;
      margin-right: 171px;
      width: 340px;
      height: 76px;
      background: url("../assets/zxx_img/bei/logo.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
.navIcon {
  width: 20px;
  height: 20px;
  margin-left: 20px;
}
.erweima-box {
  display: flex;
  width: 253px;
  margin-left: 30px;
  justify-content: flex-end;
  margin-top: 7px;

  .erweima {
    width: 70px;
    margin: 0px 5px;
  }
}

.title_top {
  cursor: pointer;
  // font-weight: bold;
  padding-bottom: 3px;
  color: #0a65ff;
}

.year {
  position: absolute;
  right: 10px;
  top: 0;
  cursor: pointer;
}

.linjian {
  text-align: center;
  font-size: 24px;
  color: #409eff;
}

.Bjiang {
  cursor: pointer;
  width: 112px;
  height: 36px;
  background: #3778ff
    linear-gradient(176deg, rgba(255, 255, 255, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  box-shadow: 0px 2px 6px 0px rgba(72, 97, 254, 0.29);
  border-radius: 18px;
  /* border: 1px solid #FFFFFF; */
  text-align: center;
  line-height: 36px;
  color: white;
}
.coal {
  width: 112px;
  height: 36px;
  color: #4861fe;
  border: 1px solid #4861fe;
  box-shadow: 0px 10px 10px #e8e9f5;
  cursor: pointer;
  // width: 200px;
  // height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 18px;
}

.Njiang {
  cursor: pointer;
  width: 112px;
  height: 36px;
  background: #ff7e20
    linear-gradient(176deg, rgba(255, 255, 255, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  box-shadow: 0px 2px 6px 0px rgba(254, 169, 72, 0.29);
  border-radius: 18px;
  /* border: 1px solid #FFFFFF; */
  text-align: center;
  line-height: 36px;
  color: white;
}
::v-deep .el-select {
  border-right: 1px solid #4861fe;
}
::v-deep .input-with-select {
  border: none;
}
</style>